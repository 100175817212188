import React, { useEffect, useState } from 'react'
import { Container, Card, Button, InputGroup, Form, Row, Col, Modal } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Sidebar from './sidebar';
import SidebarMob from './siderbar_mobile';

import { BsSearch } from "react-icons/bs";
import { IoIosAddCircleOutline } from "react-icons/io";

import bdot from '../assests/bluedot.svg';
import gdot from '../assests/greendot.svg';
import mini from '../assests/mini.svg';
import closed from '../assests/closedtruck.svg';
import heavy from '../assests/heavy.svg';
import tank from '../assests/tank.svg';
import openbody from '../assests/opentruck.svg';
import bus from '../assests/bus.svg';
import Usermenu from './user_menu';

import DOMPurify from 'dompurify';
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Truck() {
    const userid = sessionStorage.getItem('id')
    const nevigate = useNavigate()
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [show5, setShow5] = useState(false);
    const [modal, setmodal] = useState(false);
    const [form, setForm] = useState({ 'userid': userid })
    const [truck, setTruck] = useState([]);
    const [supplier, setSupplier] = useState([]);
    const [driver, setDriver] = useState([]);
    const [display, setDisplay] = useState("none");
    const [display2, setDisplay2] = useState("none");
    const [search, setSearch] = useState('')
    const [truckdata, setTruckData] = useState({})

    const [activeTruck, setActiveTruck] = useState('mini_truck')

    const notify = () => toast("Truck Added Successfully !")
    const notify_error = () => toast("Something Went Wrong !!")


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => {
        setShow2(true);
        setShow(false);
    }
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => {
        setShow3(true);
        setShow(false);
    }
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => {
        setShow4(true);
    }
    const handleClose5 = () => setShow5(false);
    const handleShow5 = () => {
        setShow5(true);
    }

    const handletruck = (id) => {
        nevigate(`/truckone/${id}`)
    }

    const handletype = (type) => {
        setForm({
            ...form,
            'type': type
        })
    }

    const handleinput = (e) => {
        const sanitizedValue = DOMPurify.sanitize(e.target.value)
        setForm({
            ...form,
            [e.target.name]: sanitizedValue
        })
        if (e.target.name === 'ownership') {
            if (sanitizedValue === 'Market Truck') {
                setDisplay2('block');
                setDisplay('none');
            } else {
                setDisplay2('none');
                setDisplay('block');
            }
        }
    }

    const handlesubmit = (e) => {
        e.preventDefault()
        axios.post('https://v4masters.com/backend/public/addtruck', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                if (response.data === "success") {
                    notify()
                    setTimeout(() => {
                        handleClose5()
                        getTruck();
                    }, 2000)
                } else {
                    notify_error()
                }
            })
            .catch((error) => {

                //console.log(error)
            })
    }

    const getTruck = (e) => {
        axios.get('https://v4masters.com/backend/public/gettruck2')
            .then((response) => {

                if (response.data === 'error') {
                    setTruck([])
                }
                else
                    setTruck(response.data);

                var market = 0;
                var owned = 0;

                for (let i = 0; i < response.data.length; i++) {

                    if (response.data[i].owership === 'Market Truck') {
                        market += 1;

                    } else if (response.data[i].owership === 'My Truck') {
                        owned += 1;
                    }

                }
                setTruckData({
                    "all_truck": market + owned,
                    'market': market,
                    "owned": owned
                })

            })
            .catch((error) => {
                //console.log(error);
            })
    }
    // const getTruck = (e) => {
    //     axios.get(`https://v4masters.com/backend/public/gettruckdetail/${userid}`)
    //         .then((response) => {

    //            //console.log("truckkkkkkkkk",response.data)
    //             if (response.data === 'error') {
    //                 setTruck([])
    //             }
    //             else
    //                 setTruck(response.data);

    //             var market = 0;
    //             var owned = 0;
    //             var all = 0;
    //             for (let i = 0; i < response.data.length; i++) {
    //                 if (response.data[i].owership === 'Market Truck') {
    //                     all+=1;
    //                     market += 1;                      
    //                 } else if (response.data[i].owership === 'My Truck') {
    //                     owned += 1;
    //                     all+=1;
    //                 }
    //             }
    //             setTruckData({
    //                 "all_truck":all,
    //                 'market': market,
    //                 "owned": owned
    //             })

    //         })
    //         .catch((error) => {
    //            //console.log(error);
    //         })
    // }
    const getsupplier = () => {
        axios.get('https://v4masters.com/backend/public/getsupplier')
            .then((response) => {
                ////console.log(response.data, 'resposne')
                if (response.data === 'error') {
                    setSupplier([])
                }
                else
                    setSupplier(response.data)
            })
            .catch((error) => {
                //console.log(error);
            })
    }
    const getDriver = (e) => {
        axios.get('https://v4masters.com/backend/public/getdriver')
            .then((response) => {
                ////console.log(response.data, 'resposne')
                if (response.data === 'error') {
                    setDriver([])
                }
                else
                    setDriver(response.data)
            })
            .catch((error) => {
                //console.log(error)
            })
    }
    useEffect(() => {
        getTruck();
        getsupplier();
        getDriver();
    }, [])

    const filteredUsers = truck?.filter(user =>
        user.reg_no.toLowerCase().includes(search.toLowerCase()) ||
        user.type.toLowerCase().includes(search.toLowerCase()) ||
        user.owership.includes(search)
    );
    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable

                theme="dark"

            />
            <Container fluid className='px-0 vh-100 d-flex'>
                <Sidebar />
                <SidebarMob />

                <div className='m-0 p-0 w-100 padding-20'>
                    <Container fluid className='d-flex justify-content-between px-5 mt-4 align-items-center'>
                        <h1 className='text-blue fw-6'>Truck</h1>

                        <div className="d-flex justify-content-end align-items-center">
                            {/* <button className='btn bg-blue fs-5 fw-6 text-white me-3 h-50 rounded-4' onClick={handleShow5}><IoIosAddCircleOutline className='me-2 fs-4 mb-1' />Add Trucks</button> */}
                            <Usermenu />
                        </div>
                    </Container>

                    <Card className=' mx-5 mt-4 pb-4 shadow shadow-md ' >
                        <div className='d-flex justify-content-between align-items-center mt-4 pe-3 border-bottom '>
                            <div className="d-flex">
                                <NavLink className='text-decoration-none border border-1 border-dark fs-5 fw-6 text-dark me-1 h-50 rounded-4 p-2 ms-2'>All Trucks : {truckdata.all_truck ? truckdata.all_truck : '0'}</NavLink>
                                <NavLink className='text-decoration-none border border-1 border-dark fs-5 fw-6 text-dark me-1 h-50 rounded-4 p-2 ms-2'>Market Truck : {truckdata.market ? truckdata.market : '0'}</NavLink>
                                <NavLink className='text-decoration-none border border-1 border-dark fs-5 fw-6 text-dark me-3 h-50 rounded-4 p-2 ms-2'>My Truck : {truckdata.owned ? truckdata.owned : '0'}</NavLink>


                            </div>
                            <div className='text-end'>
                                <InputGroup className="mb-3 mt-3">
                                    <Form.Control
                                        placeholder="Search.."
                                        aria-label="search"
                                        aria-describedby="basic-addon2"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    <InputGroup.Text id="basic-addon2"><BsSearch /></InputGroup.Text>
                                </InputGroup>
                            </div>
                        </div>
                        <div className="table-responsive px-2 pt-4">

                            <table className='table table-striped  table-hover'>
                                <thead>
                                    <tr>
                                        <th><h4>#</h4></th>
                                        <th><h4>Truck No</h4></th>
                                        <th><h4>Truck Type</h4></th>
                                        <th><h4>Truck Capacity</h4></th>
                                        <th><h4>Ownership</h4></th>
                                        <th><h4>Supplier</h4></th>
                                        <th><h4>Driver</h4></th>
                                        <th><h4>Driver Contact</h4></th>
                                        <th><h4>Status</h4></th>
                                        <th><h4>Action</h4></th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {/* (console.log(truck ,'truch')) */}
                                    {truck && truck.length > 0 ? (filteredUsers?.slice().reverse().map((item, index) => (
                                        <tr className='pt-4' key={index}>
                                            <td ><h5 className='mt-2'>{index + 1}</h5></td>
                                            <td><h5 className='mt-2'>{item.reg_no}</h5></td>
                                            <td><h5 className='mt-2'>{item.type}</h5></td>
                                            <td><h5 className='mt-2'>{item.capacity} Tonne</h5></td>
                                            <td><h5 className='mt-2'>{item.owership}</h5></td>
                                            <td><h5 className='mt-2' >{supplier.find(Sup_id => Sup_id.id === item.supplier)?.name || ''}</h5></td>
                                            <td><h5 className='mt-2' >{driver.find(Sup_id => Sup_id.id === item.driver)?.name || ''}</h5></td>
                                            <td><h5 className='mt-2' >{driver.find(Sup_id => Sup_id.id === item.driver)?.phone || ''}</h5></td>
                                            <td ><div className="d-flex justify-content-start align-items-center mt-2">
                                                {item.bilty_current_weight === "0" ? <><img src={gdot} alt="image..." />
                                                    <h5 className='mb-0 ms-1'>Available</h5></> : <> <img src={bdot} alt="image..." />
                                                    <h5 className='mb-0 ms-1'>Ontrip</h5></>}
                                            </div></td>
                                            <td><Button className='mt-2 bg-blue text-white btn-sm rounded-4' onClick={() => { handletruck(item.id) }}>View Details</Button></td>
                                        </tr>
                                    ))) : (

                                        <tr className='pt-4' >
                                            <td colSpan={7} className='text-center'><h5 className='mt-2'>No Truck Found</h5></td>
                                        </tr>

                                    )}

                                </tbody>
                            </table>
                        </div>

                    </Card>
                </div>
            </Container>
            <Modal show={show} onHide={handleClose} centered >
                <Modal.Header className='bg-blue rounded-top-4'>
                    <Modal.Title className='fs-4 text-white'>Choose Driver</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>

                        <Row className='px-3 g-2'>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Driver Name</h6>
                                <select className='form-select rounded-4'>
                                    <option value=" ">Raja</option>
                                    <option value=" ">Radhe</option>
                                    <option value=" ">Ajay</option>
                                </select>
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6 mt-3'>Recent Drivers</h6>
                                <div className="d-flex ">
                                    <Button className='bg-blue rounded-4 btn-sm me-2'>Raja</Button>
                                    <Button className='bg-blue rounded-4 btn-sm me-2'>Radhe</Button>
                                    <Button className='bg-blue rounded-4 btn-sm me-2'>Ajay</Button>
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' onClick={modal ? handleShow2 : handleShow3} >Save</Button>
                                </div>
                            </Col>
                        </Row>


                    </form>
                </Modal.Body>
            </Modal>
            <Modal show={show2} onHide={handleClose2} centered >
                <Modal.Header className='bg-blue rounded-top-4'>
                    <Modal.Title className='fs-4 text-white'>Driver Gave (-)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>

                        <Row className='px-3 g-2'>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Total Amount</h6>
                                <input type="Number" className='form-control mb-3  rounded-4' placeholder='Total Amount' />
                            </Col>
                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Reason</h6>
                                <select className='form-select rounded-4'>
                                    <option value=" ">Fuel Expense</option>
                                    <option value=" ">Loading Charges</option>
                                    <option value=" ">Uploading Charges</option>
                                    <option value=" ">Detention Charges</option>
                                    <option value=" ">Union Charges</option>
                                    <option value=" ">Toll Charges</option>
                                    <option value=" ">Police Expense</option>
                                    <option value=" ">RTO Expense</option>
                                </select>
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6 mt-3'>Date</h6>
                                <input type="date" className='form-control  mb-3 rounded-4' placeholder='Date' />
                            </Col>
                            <Col md={12}>
                                <h6 className='text-blue fw-6 mt-3'>Type Note</h6>
                                <input type="text" className='form-control  mb-3 rounded-4' placeholder='Type Note' />
                            </Col>

                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose2}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' onClick={handleShow2} >Save</Button>
                                </div>
                            </Col>
                        </Row>


                    </form>
                </Modal.Body>
            </Modal>
            <Modal show={show3} onHide={handleClose3} centered >
                <Modal.Header className='bg-blue rounded-top-4'>
                    <Modal.Title className='fs-4 text-white'>Driver Got (+)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>

                        <Row className='px-3 g-2'>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Total Amount</h6>
                                <input type="Number" className='form-control mb-3  rounded-4' placeholder='Total Amount' />
                            </Col>
                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Reason</h6>
                                <select className='form-select rounded-4'>
                                    <option value=" ">Trip Advance</option>
                                    <option value=" ">Trip Payment</option>
                                    <option value=" ">Party Advance</option>
                                    <option value=" ">Party Payment</option>
                                    <option value=" ">Driver Payment</option>

                                </select>
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6 mt-3'>Date</h6>
                                <input type="date" className='form-control  mb-3 rounded-4' placeholder='Date' />
                            </Col>
                            <Col md={12}>
                                <h6 className='text-blue fw-6 mt-3'>Type Note</h6>
                                <input type="text" className='form-control  mb-3 rounded-4' placeholder='Type Note' />
                            </Col>

                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose3}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' onClick={handleShow3} >Save</Button>
                                </div>
                            </Col>
                        </Row>


                    </form>
                </Modal.Body>
            </Modal>


            <Modal show={show4} onHide={handleClose4} centered >
                <Modal.Header className='bg-blue rounded-top-4'>
                    <Modal.Title className='fs-4 text-white'>Add Driver Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>

                        <Row className='px-3 g-2'>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Driver Name</h6>
                                <input type="text" className='form-control mb-3   rounded-4' placeholder='Enter Name' />
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Mobile Number</h6>
                                <input type="Number" className='form-control  mb-3   rounded-4' placeholder='+91 Enter Mobile Number' />
                            </Col>

                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose4}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' >Save</Button>
                                </div>
                            </Col>
                        </Row>

                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={show5} onHide={handleClose5} centered >
                <Modal.Header className='bg-blue rounded-top-4 '>
                    <Modal.Title className='fs-4 text-white'>Add Truck</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={handlesubmit}>

                        <Row className='px-3 g-2'>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Register Number</h6>
                                <input type="text" className='form-control mb-3   rounded-4' name="reg_no" placeholder='Register Number' onChange={handleinput} />
                            </Col>

                            <Col md={12} className='border-bottom border-1 border-dark px-0 mb-3 pb-3' >
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className={`btn btn-truck text-center w-100 h-100 rounded-4 p-1 ${activeTruck == 'mini_truck' ? 'btn-truck_active' : ''}`} onClick={() => { handletype("Mini Truck/LCV"); setActiveTruck('mini_truck') }}>
                                        <img src={mini} alt="" />
                                        <h6 className='text-blue fw-6'>Mini Truck/LCV</h6>
                                    </div>
                                    <div className={`btn btn-truck text-center w-100 rounded-4 p-1 ${activeTruck == 'open_body' ? 'btn-truck_active' : ''}`} onClick={() => { handletype("Open Body Truck"); setActiveTruck('open_body') }}>
                                        <img src={openbody} className="mb-1" alt="" />
                                        <h6 className='text-blue fw-6'>Open Body Truck</h6>
                                    </div>
                                    <div className={`btn btn-truck text-center w-100 rounded-4 p-1 ${activeTruck == 'closed_container' ? 'btn-truck_active' : ''}`} onClick={() => { handletype("Closed Container"); setActiveTruck('closed_container') }}>
                                        <img src={closed} alt="" />
                                        <h6 className='text-blue fw-6'>Closed Container</h6>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-around align-items-center mt-3">
                                    <div className={`btn btn-truck text-center w-100 rounded-4 p-1 ${activeTruck == 'tank' ? 'btn-truck_active' : ''}`} onClick={() => { handletype("Tank"); setActiveTruck('tank') }}>
                                        <img src={tank} alt="" />
                                        <h6 className='text-blue fw-6'>Tank</h6>
                                    </div>
                                    <div className={`btn btn-truck text-center w-100 rounded-4 p-1 ${activeTruck == 'heavy' ? 'btn-truck_active' : ''}`} onClick={() => { handletype("Heavy"); setActiveTruck('heavy') }}>
                                        <img src={heavy} alt="" />
                                        <h6 className='text-blue fw-6'>Heavy</h6>
                                    </div>
                                    <div className={`btn btn-truck text-center w-100 rounded-4 p-1 ${activeTruck == 'bus' ? 'btn-truck_active' : ''}`} onClick={() => { handletype("Bus"); setActiveTruck('bus') }}>
                                        <img src={bus} alt="" />
                                        <h6 className='text-blue fw-6'>Bus</h6>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Owner Ship</h6>
                                <div className="d-flex">
                                    <div className="form-check me-4">
                                        <input className="form-check-input" type="radio" value="Market Truck" name="ownership" id="flexCheckChecked1" onChange={handleinput} />
                                        <label className="form-check-label" htmlFor="flexCheckChecked1">
                                            Market Truck
                                        </label>
                                    </div>
                                    <div className="form-check me-4">
                                        <input className="form-check-input" type="radio" value="My Truck" name="ownership" id="flexCheckChecked2" onChange={handleinput} />
                                        <label className="form-check-label" htmlFor="flexCheckChecked2">
                                            My Truck
                                        </label>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12} style={{ display: display }}>
                                {/* <h6 className='text-blue fw-6'>Select Driver</h6> */}
                                <select className='form-select rounded-4 my-2' name="driver" onChange={handleinput}>
                                    <option value="" defaultValue=''>Select Driver</option>
                                    {driver.slice().reverse().map((item, index) => (
                                        <option value={item.id} key={index}>{item.name}</option>
                                    ))}

                                </select>
                            </Col>
                            <Col md={12} style={{ display: display2 }}>
                                {/* <h6 className='text-blue fw-6'>Select Driver</h6> */}
                                <select className='form-select rounded-4 my-2' name="supplier" onChange={handleinput}>
                                    <option value=" " defaultValue=''>Select Supplier</option>
                                    {supplier.slice().reverse().map((item, index) => (
                                        <option value={item.id} key={index}>{item.name}</option>
                                    ))}

                                </select>
                            </Col>
                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose5}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' type="submit" >Confirm</Button>
                                </div>
                            </Col>
                        </Row>

                    </form>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Truck;