import React, { useEffect, useState } from 'react'
import { Container, ListGroup } from 'react-bootstrap'
import { NavLink } from "react-router-dom";
import logo from '../assests/logo.svg'
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
function Sidebar() {
  const user_type = sessionStorage.getItem('user_type')
  const { userData } = useAuth();
  const userid = sessionStorage.getItem('id')
  const [form, setForm] = useState({})
  let location = useLocation();
  const currPage = location.pathname
  const getuser = () => {
    axios.get(`https://v4masters.com/backend/public/getuserone/${userid}`)

      .then((response) => {
        delete response.data[0].password

        //console.log("userdata", response.data);
        setForm(response.data[0])
      })
      .catch((error) => {
        //console.log(error)
      })
  }

  useEffect(() => {
    ////console.log("user_type", user_type);
    ////console.log("userid", userid);
    getuser();
  }, [])
  return (

    <aside className='sidebar sidebar_main m-0  h-100'>
      <Container className='text-center pt-4'>
        <img src={logo} alt="" />
      </Container>

      {user_type === '0' ? <>

        <ListGroup variant="flush" className='nav ps-3 pe-2 '>
          <ListGroup.Item className={`py-0 menu_item ${currPage === '/' ? 'menu_item_active' : ''}`}>
            <NavLink to={'/'} className={`menu_link nav-img3 fs-5 ${currPage === '/' ? 'menu_link_active nav_img3_active' : ""}`}>Bilty</NavLink>
          </ListGroup.Item>
          <ListGroup.Item className={`py-0 menu_item ${currPage === '/user' ? 'menu_item_active' : ''}`}>
            <NavLink to={'/user'} className={`menu_link nav-img1 fs-5 ${currPage === '/user' ? 'menu_link_active nav_img1_active' : ""}`}>Users</NavLink>
          </ListGroup.Item>
          {user_type === '0' && userData && userData.is_main === '1' && (
            <ListGroup.Item className={`py-0 menu_item ${currPage === '/admin' ? 'menu_item_active' : ''}`}>
              <NavLink
                to="/admin"
                className={`menu_link nav-img1 fs-5 ${currPage === '/admin' ? 'menu_link_active nav_img1_active' : ''}`}
              >
                Admin
              </NavLink>
            </ListGroup.Item>
          )}
          {/* <ListGroup.Item className={`py-0 menu_item ${currPage === '/deliveryuser' ? 'menu_item_active' : ''}`}>
            <NavLink to={'/deliveryuser'} className={`menu_link nav-img2 fs-5 ${currPage === '/deliveryuser' ? 'menu_link_active nav_img2_active' : ""}`}>Delivery User</NavLink>
          </ListGroup.Item> */}
          <ListGroup.Item className={`py-0 menu_item ${currPage === '/parties' ? 'menu_item_active' : ''}`}>
            <NavLink to={'/parties'} className={`menu_link nav-img2 fs-5 ${currPage === '/parties' ? 'menu_link_active nav_img2_active' : ""}`}>Parties</NavLink>
          </ListGroup.Item>

          <ListGroup.Item className={`py-0 menu_item ${currPage === '/expenses' ? 'menu_item_active' : ''}`}>
            <NavLink to={'/expenses'} className={`menu_link nav-img2 fs-5 ${currPage === '/expenses' ? 'menu_link_active nav_img2_active' : ""}`}>Expenses</NavLink>
          </ListGroup.Item>
          {/* <ListGroup.Item className={`py-0 menu_item ${currPage === '/supplier' ? 'menu_item_active' : ''}`}>
            <NavLink to={'/supplier'} className={`menu_link nav-img4 fs-5 ${currPage === '/supplier' ? 'menu_link_active nav_img4_active' : ""}`}>Suppliers</NavLink>
          </ListGroup.Item> */}
          {/* <ListGroup.Item className={`py-0 menu_item ${currPage === '/driver' ? 'menu_item_active' : ''}`}>
            <NavLink to={'/driver'} className={`menu_link nav-img5 fs-5 ${currPage === '/driver' ? 'menu_link_active nav_img5_active' : ""}`}>Drivers</NavLink>
          </ListGroup.Item> */}
          <ListGroup.Item className={`py-0 menu_item ${currPage === '/truck' ? 'menu_item_active' : ''}`}>
            <NavLink to={'/truck'} className={`menu_link nav-img6 fs-5 ${currPage === '/truck' ? 'menu_link_active nav_img6_active' : ""}`}>Trucks</NavLink>
          </ListGroup.Item>
        </ListGroup></> :

        <ListGroup variant="flush" className='nav ps-3 pe-2 '>

          <ListGroup.Item className={`py-0 menu_item ${currPage === '/' ? 'menu_item_active' : ''}`}>
            <NavLink to={'/'} className={`menu_link nav-img3 fs-5 ${currPage === '/' ? 'menu_link_active nav_img3_active' : ""}`}>Bilty</NavLink>
          </ListGroup.Item>
          {userData?.parties === '1' ?
            <ListGroup.Item className={`py-0 menu_item ${currPage === '/parties' ? 'menu_item_active' : ''}`}>
              <NavLink to={'/parties'} className={`menu_link nav-img2 fs-5 ${currPage === '/parties' ? 'menu_link_active nav_img2_active' : ""}`}>Parties</NavLink>
            </ListGroup.Item>
            :
            ""}
          {userData?.supplier === '1' ?
            <ListGroup.Item className={`py-0 menu_item ${currPage === '/supplier' ? 'menu_item_active' : ''}`}>
              <NavLink to={'/supplier'} className={`menu_link nav-img4 fs-5 ${currPage === '/supplier' ? 'menu_link_active nav_img4_active' : ""}`}>Suppliers</NavLink>
            </ListGroup.Item> : ""}
          {userData?.accountant === '1' ?
            <ListGroup.Item className={`py-0 menu_item ${currPage === '/expenses' ? 'menu_item_active' : ''}`}>
              <NavLink to={'/expenses'} className={`menu_link nav-img2 fs-5 ${currPage === '/expenses' ? 'menu_link_active nav_img2_active' : ""}`}>Expenses</NavLink>
            </ListGroup.Item> : ""}

          {/* {userData?.drivers === '1' ?
            <ListGroup.Item className={`py-0 menu_item ${currPage === '/driver' ? 'menu_item_active' : ''}`}>
              <NavLink to={'/driver'} className={`menu_link nav-img5 fs-5 ${currPage === '/driver' ? 'menu_link_active nav_img5_active' : ""}`}>Drivers</NavLink>
            </ListGroup.Item> : ""} */}
          {userData?.trucks === '1' ?
            <ListGroup.Item className={`py-0 menu_item ${currPage === '/truck' ? 'menu_item_active' : ''}`}>
              <NavLink to={'/truck'} className={`menu_link nav-img6 fs-5 ${currPage === '/truck' ? 'menu_link_active nav_img6_active' : ""}`}>Trucks</NavLink>
            </ListGroup.Item> : ""}
        </ListGroup>}

    </aside>


  )
}

export default Sidebar

