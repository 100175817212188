import React, { useEffect, useState } from 'react'
import { Container, Card, Button, InputGroup, Form, Row, Col, Modal } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useParams } from 'react-router-dom';
import Sidebar from '../sidebar';
import user_logo from '../../assests/user-logo.svg'
import user_img from '../../assests/user_image.svg'
import { BsSearch } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEye } from "react-icons/fa";
import { IoIosAddCircleOutline } from "react-icons/io";
import SidebarMob from '../siderbar_mobile';
import { useNavigate } from 'react-router-dom';
import Usermenu from '../user_menu';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import html2pdf from 'html2pdf.js';
function ExpPassbook() {
    const nevigate = useNavigate()
    var { id } = useParams()
    const [show3, setShow3] = useState();
    const [show2, setShow2] = useState();
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const exportToExcel = () => {
        // Get the HTML table element
        const table = document.getElementById('data-table');

        // Convert HTML table to a worksheet
        const ws = XLSX.utils.table_to_sheet(table);

        // Create a new workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Generate Excel file as binary string
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        // Convert binary string to blob
        const buf = new ArrayBuffer(wbout.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < wbout.length; i++) {
            view[i] = wbout.charCodeAt(i) & 0xFF;
        }
        const blob = new Blob([buf], { type: 'application/octet-stream' });

        // Trigger download
        saveAs(blob, `${staff.name ? staff.name : 'data'}.xlsx`);
    };
    const [staff, setStaff] = useState([])
    const [salary, setSalary] = useState([])
    const [amount, setAmount] = useState({
        payment_mode: '',
    })
    const [updateSalary, setUpdateSalary] = useState({
        total_amount: '',
        amount_paid: '',
        payment_mode: '',
    });


    const getstaff = () => {
        axios.get(`https://v4masters.com/backend/public/getexpensesbyid/${id}`)
            .then((response) => {
                setSalary(response.data[0].salary)
                delete response.data[0].salary
                setStaff(response.data[0])
            })
            .catch((error) => {
                //console.log("error", error)
            })
    }
    const updatebalancestaff = (e) => {
        e.preventDefault()
        if (amount?.amount_paid > 0 && validateForm()) {
            const newid = toast.loading("Please wait...", {
                type: "loading",
                isLoading: true,
                icon: true,
                autoClose: 3000,
                closeButton: true,
                closeOnClick: true,
            })

            axios.post(`https://v4masters.com/backend/public/updatebalanceexpenses/${id}`, amount)
                .then((response) => {
                    if (response.data === 'error') {
                        toast.update(newid, {
                            render: "Something went wrong...",
                            type: "error",
                            isLoading: false,
                            icon: true,
                            autoClose: 3000,
                            closeButton: true,
                            closeOnClick: true,
                        })

                    }
                    setTimeout(() => {
                        toast.update(newid, {
                            render: "Update Successfully...",
                            type: "success",
                            isLoading: false,
                            icon: true,
                            autoClose: 3000,
                            closeButton: true,
                            closeOnClick: true,
                        })
                        setAmount([])
                        getstaff()
                    }, 1000);

                })
                .catch((error) => {
                    //console.log("error", error)
                })
        }
    }
    const addbalancestaff = (e) => {
        e.preventDefault()
        if (!updateSalary?.total_amount || isNaN(updateSalary.total_amount) || Number(updateSalary.total_amount) <= 0) {
            toast.error("Total amount must be a positive number.");
            return;
        }
        if (!updateSalary?.amount_paid || isNaN(updateSalary.amount_paid) || Number(updateSalary.amount_paid) < 0) {
            toast.error("Amount paid must be a non-negative number.");
            return;
        }
        if (!updateSalary?.payment_mode.trim()) {
            toast.error("Payment mode is required.");
            return;
        }
        if (updateSalary?.total_amount && updateSalary?.payment_mode && updateSalary?.amount_paid) {
            const newid = toast.loading("Please wait...", {
                type: "loading",
                isLoading: true,
                icon: true,
                autoClose: 3000,
                closeButton: true,
                closeOnClick: true,
            })

            axios.post(`https://v4masters.com/backend/public/addbalanceexpenses/${id}`, updateSalary)
                .then((response) => {
                    if (response.data === 'error') {
                        toast.update(newid, {
                            render: "Something went wrong...",
                            type: "error",
                            isLoading: false,
                            icon: true,
                            autoClose: 3000,
                            closeButton: true,
                            closeOnClick: true,
                        })

                    }
                    setTimeout(() => {
                        toast.update(newid, {
                            render: "Added Successfully...",
                            type: "success",
                            isLoading: false,
                            icon: true,
                            autoClose: 3000,
                            closeButton: true,
                            closeOnClick: true,
                        })
                        setUpdateSalary([])
                        getstaff()

                    }, 1000);

                })
                .catch((error) => {
                    //console.log("error", error)
                })
        }

    }
    const handleinput = (e) => {
        const { name, value } = e.target;
        if (name === 'amount_paid') {
            const numericValue = Number(value);

            if (numericValue <= staff?.balance_amount && numericValue >= 0) {
                const balance = parseInt(staff?.balance_amount) - numericValue
                setAmount(prevState => ({ ...prevState, [name]: value, 'total_amount': staff?.balance_amount || 0, 'balance_amount': balance || 0, 'payment_status': balance === 0 ? 'paid' : 'unpaid', 'staff_amount_paid': parseInt(staff?.amount_paid || 0) + numericValue }));
            }
        }
        else {
            setAmount(prevState => ({ ...prevState, [name]: value }));
        }
        setErrors({
            ...errors,
            [name]: '',
        });
    };
    const handlesalaryinput = (e) => {
        const { name, value } = e.target;
        if (name === 'amount_paid') {
            const numericValue = Number(value);
            if (numericValue <= parseInt(updateSalary?.total_amount)) {
                setUpdateSalary(prevState => ({ ...prevState, [name]: value, 'balance_amount': (parseInt(updateSalary?.total_amount) - numericValue || 0), 'staff_balance_amount': parseInt(staff?.balance_amount) + parseInt(updateSalary?.total_amount) - numericValue, 'staffsalary_amount': parseInt(staff?.balance_amount) + parseInt(updateSalary?.total_amount), 'payment_status': updateSalary?.balance_amount === 0 ? 'Paid' : 'Pending', 'staff-amountpaid': (parseInt(staff?.amount_paid || 0) + numericValue) || 0 }));
            }
        }
        else if (name === 'total_amount') {
            const numericValue = Number(value);
            const balance = parseInt(staff?.balance_amount) - numericValue
            setUpdateSalary(prevState => ({ ...prevState, [name]: numericValue, 'balance_amount': (numericValue - parseInt(updateSalary?.amount_paid) || 0), 'staff_total_amount': parseInt(staff?.total_amount) + numericValue, 'staffsalary_amount': parseInt(staff?.balance_amount) + numericValue, 'payment_status': updateSalary?.balance_amount === 0 ? 'Paid' : 'Pending', 'staff-amountpaid': (parseInt(staff?.amount_paid || 0) + parseInt(updateSalary?.amount_paid) || 0) }));
        }
        else {
            setUpdateSalary(prevState => ({ ...prevState, [name]: value }));
        }

    };
    const [errors, setErrors] = useState()
    // Validate the form
    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!amount?.payment_mode.trim()) {
            newErrors.payment_mode = 'Payment mode is required';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };


    useEffect(() => {
        getstaff();
    }, [id])

    const generatePDF = () => {
        const element = document.getElementById('targetLR');
        if (!element) {
            return;
        }

        const opt = {
            margin: 0.1, // Adjust margins as needed
            filename: `${staff?.staff_name || 'Staff'}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 3, logging: true }, // Increase scale for better quality
            jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape', pageBreak: { mode: 'avoid-all' } }, // Avoid breaking content if possible
            pagebreak: { mode: 'avoid-all' } // Avoid breaking content if possible
        };
        html2pdf().from(element).set(opt).save();
    };

    return (
        <div>
            {/* {JSON.stringify(staff)} */}
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable

                theme="dark"

            />
            <Container fluid className='px-0 vh-100 d-flex'>
                <Sidebar />
                <SidebarMob />
                <div className='m-0 p-0 w-100 padding-20'>
                    <Container fluid className='d-flex justify-content-between px-5 mt-4 align-items-center'>
                        <h1 className='text-blue fw-6'>{''}</h1>
                        {/* <h1 className='text-blue fw-6'>{staff?.staff_name}</h1> */}

                        <div className="d-flex justify-content-end align-items-center">

                            <Usermenu />
                        </div>
                    </Container>
                    <Card className='d-flex justify-content-between  mt-4 align-items-center mx-5 flex-row shadow shadow-sm rounded-4 px-2 py-4'>
                        <div className="d-flex">
                            <Button className='bg-blue fs-5 fw-6 text-white border border-dark  h-50 rounded-4' onClick={handleShow3}>Pay Balance</Button>
                        </div>
                        <div className="d-flex">
                            <Button className=' bg-white fs-5 fw-6 text-dark border border-dark ms-4 me-2 h-50 rounded-4'>Total Balance : <span className='text-blue ms-2'> {staff?.total_amount} Rs</span></Button>
                            <Button className='bg-blue fs-5 fw-6 text-white border border-dark  h-50 rounded-4' onClick={handleShow2}>Add Salary </Button>
                        </div>


                    </Card>
                    <Card className=' mx-5 mt-2 pb-4 shadow shadow-md overflowy' style={{ maxHeight: "67vh" }}>
                        <div className='d-flex justify-content-between align-items-center mt-2 pe-3 '>
                            {/* <div className="d-flex">
                                <Button className=' bg-white fs-5 fw-6 text-dark border border-dark ms-4 me-2 h-50 rounded-4'>Total Party Balance :<span className='text-blue ms-2'> {data.balance}</span></Button>
                                <Button className=' bg-white fs-5 fw-6 text-dark border border-dark  h-50 rounded-4 me-2'>Total Active Trips : {data.trips}</Button>
                                <Button className=' bg-white fs-5 fw-6 text-dark border border-dark me-2 h-50 rounded-4'>Trip Balance <span className='text-blue ms-2'> $37,0258</span></Button>

                            </div> */}
                            <div className="d-flex pe-3 mt-2">
                                <div className='dropdown border border-dark rounded-4 ms-4 me-2'>
                                    <Button className='bg-white fs-5 fw-6 text-dark  h-50  dropdown-toggle p-2' type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">All Months</Button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className="dropdown-item text-blue fs-5 fw-6" href="#">Date</a></li>
                                        <li><a className="dropdown-item" href="#">Today</a></li>
                                        <li><a className="dropdown-item" href="#">This Week</a></li>
                                        <li><a className="dropdown-item" href="#">Last Week</a></li>
                                        <li><a className="dropdown-item" href="#">This Month</a></li>
                                        <li><a className="dropdown-item" href="#">This Year</a></li>
                                        <li><a className="dropdown-item" href="#">All Months</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='d-flex me-2 align-items-center mt-2'>


                                <Button className=' bg-danger fs-5 fw-6 text-white me-3 rounded-4 ' onClick={generatePDF}>Download</Button>

                                <InputGroup className="">
                                    <Form.Control
                                        placeholder="Search.."
                                        aria-label="search"
                                        aria-describedby="basic-addon2"
                                    />
                                    <InputGroup.Text id="basic-addon2"><BsSearch /></InputGroup.Text>
                                </InputGroup>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center  border-bottom pb-3'>
                            {/* <Button className=' bg-white fs-5 fw-6 text-dark border border-dark ms-4 me-2 h-50 rounded-4'>Trip Balance <span className='text-blue ms-2'> $37,0258</span></Button> */}

                        </div>

                        <div className="table-responsive px-2 pt-4" id='targetLR'>
                            <h1 className='text-blue fw-6'>{staff?.staff_name}</h1>
                            <table className='table table-striped  table-hover' >
                                <thead>
                                    <tr>
                                        <th><h4>#</h4></th>
                                        <th><h4>Staff Id</h4></th>
                                        <th><h4>Amount Added</h4></th>
                                        <th><h4>Amount Paid</h4></th>
                                        <th><h4>Balance Amount</h4></th>
                                        <th><h4>Payment Date</h4></th>
                                        <th><h4>Payment Status</h4></th>
                                        <th><h4>Payment Mode</h4></th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {/* {JSON.stringify(salary)} */}
                                    {salary.length > 0 ? salary.slice().sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)).map((item, index) => (
                                        <tr className='pt-4' key={index}>
                                            <td ><h5 className='mt-2'>{index + 1}</h5></td>
                                            <td><h5 className='mt-2'>{item.staffid}</h5></td>
                                            <td><h5 className='mt-2' >{item.total_amount ? item?.total_amount : '-'}</h5></td>
                                            <td><h5 className='mt-2'>{item.amount_paid ? item?.amount_paid : '-'}</h5></td>
                                            <td><h5 className='mt-2'>{item.balance_amount}</h5></td>
                                            <td><h5 className='mt-2'>    {new Date(item?.date_of_payment).toLocaleDateString('en-US', {
                                                month: 'short',
                                                day: '2-digit',
                                                year: 'numeric'
                                            })}</h5></td>

                                            <td><h5 className='mt-2'>{item?.payment_status}</h5></td>
                                            <td><h5 className='mt-2'>{item?.payment_mode}</h5></td>


                                        </tr>
                                    )) : <tr className='pt-4 text-center' >

                                        <td colSpan={7}><h5 className='mt-2'>No Record Found</h5></td>

                                    </tr>}

                                </tbody>
                            </table>
                        </div>

                    </Card>
                </div>
            </Container>
            <Modal show={show3} onHide={handleClose3} centered  >
                <Modal.Header className='bg-blue rounded-top-4'>
                    <Modal.Title className='fs-4 text-white'>Pay Balance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form >
                        <Row className='px-3 g-2 gx-3'>
                            <Col md={12}>
                                <h5 className='text-blue fw-6'>Amount to Pay  : {staff?.balance_amount || ''} </h5>
                            </Col>
                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Amount to Pay</h6>
                                <input type="text" className={`form-control rounded-4 mb-1 `} required onChange={handleinput} name="amount_paid" value={amount?.amount_paid || ''} />
                            </Col>
                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Payment Mode</h6>
                                <Form.Select className={`rounded-4 mt-1 ${errors?.payment_mode ? 'border-danger' : ''}`} required name="payment_mode" onChange={handleinput} value={amount?.payment_mode || ''} >
                                    <option value='' disabled>Select Mode</option>
                                    <option value='Online'>Online</option>
                                    <option value='Offline'>Offline</option>
                                </Form.Select>
                            </Col>
                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Remarks</h6>
                                <input type="text" className={`form-control rounded-4 mb-1 `} name="discription" value={amount?.discription || ''} onChange={handleinput} placeholder='Enter Remark' />
                            </Col>
                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose3}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' onClick={updatebalancestaff} disabled={amount?.amount_paid <= 0}>Pay</Button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
            <Modal show={show2} onHide={handleClose2} centered  >
                <Modal.Header className='bg-blue rounded-top-4'>
                    <Modal.Title className='fs-4 text-white'>Add Balance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form >
                        <Row className='px-3 g-2 gx-3'>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Total Amount</h6>
                                <input type="text" className={`form-control rounded-4 mb-1 `} name="total_amount" onChange={handlesalaryinput} value={updateSalary?.total_amount || ''} placeholder='Enter Amount' />
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'> Amount Paid</h6>
                                <input type="text" className={`form-control rounded-4 mb-1 `} name="amount_paid" onChange={handlesalaryinput} value={updateSalary?.amount_paid || ''} placeholder='Enter Amount' />
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Balance Amount</h6>
                                <input type="text" readOnly className={`form-control rounded-4 mb-1 `} name="balance_amount" value={updateSalary?.balance_amount || 0} onChange={handlesalaryinput} placeholder='Enter Amount' />
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Remarks</h6>
                                <input type="text" className={`form-control rounded-4 mb-1 `} name="discription" value={updateSalary?.discription || ''} onChange={handlesalaryinput} placeholder='Enter Amount' />
                            </Col>

                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Payment Mode</h6>
                                <Form.Select className={`rounded-4 mt-1`} onChange={handlesalaryinput} name="payment_mode" value={updateSalary?.payment_mode || ''}  >
                                    <option value=''>Select Mode</option>
                                    <option value='Online'>Online</option>
                                    <option value='Cash'>Cash</option>
                                </Form.Select>
                            </Col>

                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose2}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' onClick={addbalancestaff} disabled={updateSalary?.total_amount <= 0}>Pay</Button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ExpPassbook;