import React, { useEffect, useState } from 'react'
import { Container, Card, Button, Row, Col, Form } from 'react-bootstrap'
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Sidebar from './sidebar';
import upload from '../assests/upload.svg'
import axios from 'axios';
import Usermenu from './user_menu';
import DOMPurify from 'dompurify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function User() {
    const navigate = useNavigate()
    const admin_id = sessionStorage.getItem('id')
    const [form, setForm] = useState({
        'admin_id': admin_id,
        'is_verified': 0
    })
    const [service, setservice] = useState([])
    const [startroute, setStartRoute] = useState([])
    const [imagePreview, setImagePreview] = useState(null);


    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
    };


    const handleinput = (e) => {
        const { name, files, value, checked } = e.target;

        if (name === 'image' && files && files[0]) {
            const file = files[0];

            setForm({ ...form, [name]: file });

            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);

        } else if (name === 'is_verified') {
            setForm((prev) => ({
                ...prev,
                [name]: checked ? "1" : "0",
            }));
        } else {
            const sanitizedValue = DOMPurify.sanitize(value);
            setForm({ ...form, [name]: sanitizedValue });

        }

    }

    const handlesubmit = (e) => {
        e.preventDefault()
        const newid = toast.loading("Please wait...")
        if (form.password == form.confirmpass) {
            axios.post('https://v4masters.com/backend/public/addadmin', form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    if (response.data === "success") {
                        // setTimeout(() => {
                        toast.update(newid, {
                            render: "User Added Successfully !",
                            type: "success",
                            isLoading: false,
                            icon: true,
                            autoClose: 3000,
                            closeButton: true,
                            closeOnClick: true,
                        })

                        setTimeout(() => {
                            navigate("/admin");
                        }, 3000)
                    } else if (response.data === "Already register") {
                        // setTimeout(() => {
                        toast.update(newid, {
                            render: "User Already registered from this number !",
                            type: "info",
                            isLoading: false,
                            icon: true,
                            autoClose: 3000,
                            closeButton: true,
                            closeOnClick: true,
                        })


                    } else {
                        setTimeout(() => {
                            toast.update(newid, {
                                render: "Something went wrong !!",
                                type: "error",
                                isLoading: false,
                                icon: true,
                                autoClose: 3000,
                                closeButton: true,
                                closeOnClick: true,
                            })
                        }, 1000);
                        // notify_error()
                    }

                })
                .catch((error) => {

                    //console.log(error)
                })
        } else {
            setTimeout(() => {
                toast.update(newid, {
                    render: "Password do not matched !!",
                    type: "error",
                    isLoading: false,
                    icon: true,
                    autoClose: 3000,
                    closeButton: true,
                    closeOnClick: true,
                })
            }, 1000);
        }
    }
    const getStartRoute = (e) => {
        axios.get('https://v4masters.com/backend/public/getstartroute')
            .then((response) => {
                if (response.data === "error") {
                    toast.error('Something went wrong ...')
                }
                else {
                    setStartRoute(response.data)
                }
                //console.log(response.data)
                //console.log(startroute.length, 'start')

            })
            .catch((error) => {

                //console.log(error)
            })
    }
    useEffect(() => {
        getStartRoute()
    }, [])
    return (
        <div>
            {/* {JSON.stringify(startroute)} */}
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable

                theme="dark"

            />
            <Container fluid className='px-0 vh-100 d-flex'>
                <Sidebar />
                <div className='m-0 p-0 w-100 padding-20'>
                    <Container fluid className='d-flex justify-content-between px-5 mt-4'>
                        <h1 className='text-blue fw-6'>Admin</h1>
                        <Usermenu />
                    </Container>
                    <Card className=' mx-5 mt-4 p-4 pb-4 shadow shadow-md ' >
                        <form onSubmit={handlesubmit}>
                            <Row className='g-4'>
                                <Col md={4} className='pt-5 ps-5'>
                                    {/* <img src={upload} alt="image--" className='rounded-5' height={250} /> */}
                                    <div className='text-center'>
                                        <img
                                            src={imagePreview || upload} // Use the preview or default image
                                            alt="image-preview"
                                            className="rounded-5"
                                            height={250}

                                            style={{ cursor: 'pointer', maxWidth: "300px" }} // Make the image look clickable
                                            onClick={triggerFileInput}

                                        />
                                        <input
                                            type="file"
                                            id="fileInput"
                                            style={{ display: 'none' }}
                                            accept="image/*"
                                            name="image"
                                            // onChange={handleinput}
                                            onChange={handleinput}
                                        />

                                    </div>
                                </Col>
                                <Col md={8}>
                                    <Row className='px-3'>
                                        <h3 className='text-blue fw-6 mb-3'>Admin Detail</h3>
                                        <Col md={12}>
                                            <h6 className='text-blue fw-6'>Name</h6>
                                            <input type="text" className='form-control mb-3' required placeholder='Enter Name' name="sign_name" value={form?.sign_name || ''} onChange={handleinput} />
                                        </Col>
                                        <Col md={6}>
                                            <h6 className='text-blue fw-6'>Email</h6>
                                            <input type="email" required className='form-control  mb-3' placeholder='Enter Email' name="sign_email" value={form?.sign_email || ''} onChange={handleinput} />
                                        </Col>
                                        <Col md={6}>
                                            <h6 className='text-blue fw-6'>Phone</h6>
                                            <input type="Number" required className='form-control  mb-3' placeholder='Enter Phone' name="sign_number" value={form?.sign_number || ''} onChange={handleinput} />
                                        </Col>

                                        <Col md={12}>
                                            <h6 className='text-blue fw-6'>Address</h6>
                                            <textarea className="form-control  mb-3" value={form?.address || ''} required name="address" onChange={handleinput} id="exampleFormControlTextarea1" rows="3"></textarea>
                                            {/* <Form.Select className={`rounded-4 mt-1`} required name="address" onChange={handleinput}  >
                                                <option value=''>Select Branch Address</option>
                                                {startroute ? startroute.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.title}>
                                                            {item.title}
                                                        </option>
                                                    );
                                                }) : ''}
                                            </Form.Select> */}
                                        </Col>
                                        <Col md={6}>
                                            <h6 className='text-blue fw-6'>Password</h6>
                                            <input type="password" className='form-control ' value={form?.sign_name || ''} placeholder='Enter Password' name="password" onChange={handleinput} />
                                        </Col>
                                        <Col md={6}>
                                            <h6 className='text-blue fw-6'>Confirm Password</h6>
                                            <input type="password" className='form-control ' value={form?.sign_name || ''} placeholder='Enter Confirm Password' name="confirmpass" onChange={handleinput} />
                                        </Col>
                                        <Col md={12}>
                                            <div className="form-check form-switch mt-3">
                                                <input className="form-check-input" type="checkbox" name='is_verified' role="switch" id="flexSwitchCheckChecked" checked={parseInt(form.is_verified) === 1 ? true : false} onChange={handleinput} />
                                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Admin verified</label>
                                            </div>
                                        </Col>
                                        {/* <Col md={12}>
                                            <h3 className='text-blue fw-6 mt-4'>Permission</h3>
                                            <div className="d-flex">
                                                <div className="form-check me-4">
                                                    <input type="checkbox" id="flexCheckParties" name="parties" checked={parseInt(form.parties) === 1 ? true : false} className="form-check-input" onClick={handleinput} />
                                                    <label className="form-check-label" htmlFor="flexCheckParties">Parties</label>
                                                </div>
                                                <div className="form-check me-4">
                                                    <input type="checkbox" id="flexCheckSuppliers" name="supplier" className="form-check-input" checked={parseInt(form.supplier) === 1 ? true : false} onClick={handleinput} />
                                                    <label className="form-check-label" htmlFor="flexCheckSuppliers">Suppliers</label>
                                                </div>
                                                <div className="form-check me-4">
                                                    <input type="checkbox" id="flexCheckDrivers" name="drivers" className="form-check-input" checked={parseInt(form.drivers) === 1 ? true : false} onClick={handleinput} />
                                                    <label className="form-check-label" htmlFor="flexCheckDrivers">Drivers</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input type="checkbox" id="flexCheckTrucks" checked={parseInt(form.trucks) === 1 ? true : false} name="trucks" className="form-check-input" onClick={handleinput} />
                                                    <label className="form-check-label" htmlFor="flexCheckTrucks">Trucks</label>
                                                </div>
                                                <div className="form-check me-4">
                                                    <input type="checkbox" id="flexCheckTrips" name="trips" checked={parseInt(form.trips) === 1 ? true : false} className="form-check-input" onClick={handleinput} />
                                                    <label className="form-check-label" htmlFor="flexCheckTrips">Bilty Edit</label>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="form-check me-3">
                                                    <input type="checkbox" id="flexCheckTrucks" name="partyedit" className="form-check-input" checked={parseInt(form.partyedit) === 1 ? true : false} onClick={handleinput} />
                                                    <label className="form-check-label" htmlFor="flexCheckTrucks">Party Edit</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input type="checkbox" id="flexCheckDelivery" name="delivery" className="form-check-input" checked={parseInt(form.delivery) === 1 ? true : false} onClick={handleinput} />
                                                    <label className="form-check-label" htmlFor="flexCheckDelivery">Delivery </label>
                                                </div>
                                            </div>


                                        </Col> */}
                                        <Col md={12}>
                                            <div className="container-fluid d-flex justify-content-end mt-4 px-0" >
                                                <NavLink to='/user' className='btn bg-danger fs-5 fw-6 text-white me-3' >Cancle</NavLink>
                                                <Button type="submit" className='btn bg-blue fs-5 fw-6 text-white'>Submit</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </form>
                    </Card>
                </div>
            </Container >

        </div >
    )
}

export default User;