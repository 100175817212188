import React, { useEffect, useState } from 'react'
import { Container, Card, Button, Row, Col } from 'react-bootstrap'
import { NavLink, useParams } from 'react-router-dom';
import Sidebar from './sidebar';
import upload from '../assests/upload.svg'
import axios from 'axios';
import Usermenu from './user_menu';
import DOMPurify from 'dompurify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Admin() {

    // const {userId} = useAuth();
    const userId = sessionStorage.getItem('id');

    const [form, setForm] = useState({})
    const [form2, setForm2] = useState({})
    const [error, setError] = useState('')
    const [service, setservice] = useState([])
    const [changeimage, setChangeImage] = useState(false)
    const [imagePreview2, setImagePreview2] = useState(null);
    const [changeimage2, setChangeImage2] = useState(false)
    const [imagePreview, setImagePreview] = useState(null);
    // const [usertype, setUserType] = useState('');
    const usertype = sessionStorage.getItem('user_type')

    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
    };

    const handleinput = (e) => {
        const { name, files, value, checked } = e.target;

        if (name === 'image' && files && files[0]) {
            const file = files[0];
            setForm({ ...form, [name]: file });
            setChangeImage(true)
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else if (name === 'parties' || name === 'trucks' || name === 'supplier' || name === 'trips' || name === 'drivers') {
            setForm((prev) => ({
                ...prev,
                [name]: checked ? "1" : "0",
            }));
        } else {
            const sanitizedValue = DOMPurify.sanitize(value);
            setForm({ ...form, [name]: sanitizedValue });
        }
        //console.log(form);

    }
    const handleinput2 = (e) => {
        const { name, files, value, checked } = e.target;

        if (name === 'image' && files && files[0]) {
            const file = files[0];
            setForm2({ ...form2, [name]: file });
            setChangeImage2(true)
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview2(reader.result);
            };
            reader.readAsDataURL(file);
        } else if (name === 'parties' || name === 'trucks' || name === 'supplier' || name === 'trips' || name === 'drivers') {
            setForm2((prev) => ({
                ...prev,
                [name]: checked ? "1" : "0",
            }));
        } else {
            const sanitizedValue = DOMPurify.sanitize(value);
            setForm2({ ...form2, [name]: sanitizedValue });
        }
        //console.log(form2);

    }

    const updateadmin = (e) => {
        e.preventDefault()
        const newid = toast.loading("Please wait...")

        axios.post(`https://v4masters.com/backend/public/updateadminsetting/${userId}`, form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                toast.update(newid, {
                    render: "Admin Updated Successfully !",
                    type: "success",
                    isLoading: false,
                    icon: false,
                    autoClose: 2000,
                    closeButton: true,
                    closeOnClick: true,
                });
                // setTimeout(() => {
                delete response.data[0].password
                //console.log("userdata", response.data);
                setForm(response.data[0])
                getadmin()
                // }, 3000);
                // notify()
            })
            .catch((error) => {
                //console.log(error)
                toast.update(newid, {
                    render: "Something Went Wrong !!",
                    type: "error",
                    isLoading: false,
                    icon: false,
                    autoClose: 2000,
                    closeButton: true,
                });
                // notify_error()
            })
    }
    const getadmin = () => {
        axios.get(`https://v4masters.com/backend/public/getadminsetting/${userId}`)

            .then((response) => {
                //console.log("admin_data", response.data);
                setForm(response.data[0])
                // setUserType(response.data[0].user_type)
            })
            .catch((error) => {
                //console.log(error)
            })
    }
    const updateuser = (e) => {
        e.preventDefault()
        const newid = toast.loading("Please wait...")
        const link = usertype === '2' ? 'https://v4masters.com/backend/public/updatedeliveryuserone' : 'https://v4masters.com/backend/public/updateuserone'
        axios.post(`${link}/${userId}`, form2, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                toast.update(newid, {
                    render: "User Updated Successfully !",
                    type: "success",
                    isLoading: false,
                    icon: false,
                    autoClose: 2000,
                    closeButton: true,
                    closeOnClick: true,
                });
                delete response.data[0].password
                // setTimeout(() => {
                //console.log("userdata", response.data);
                setForm(response.data[0])
                getuser()
                // }, 3000);
                // notify()
            })
            .catch((error) => {
                //console.log(error)
                toast.update(newid, {
                    render: "Something Went Wrong !!",
                    type: "error",
                    isLoading: false,
                    icon: false,
                    autoClose: 2000,
                    closeButton: true,
                });
                // notify_error()
            })
    }
    const getuser = () => {
        setChangeImage2(false)
        const link = usertype === '2' ? 'https://v4masters.com/backend/public/getdeliveryuserone' : 'https://v4masters.com/backend/public/getuserone'
        axios.get(`${link}/${userId}`)

            .then((response) => {
                delete response.data[0].password

                //console.log("userdata", response.data);
                setForm2(response.data[0])

            })
            .catch((error) => {
                //console.log(error)
            })
    }
    useEffect(() => {
        if (usertype === '0') {
            getadmin();
        }
        else
            getuser();
    }, [])


    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable

                theme="dark"

            />

            <Container fluid className='px-0 vh-100 d-flex '>
                <Sidebar />
                <div className='m-0 p-0 w-100 padding-20'>

                    <Container fluid className='d-flex justify-content-between px-5 mt-4'>
                        <h1 className='text-blue fw-6'>Settings</h1>
                        <Usermenu />
                    </Container>
                    <Card className=' mx-5 mt-4 p-4 pb-4 shadow shadow-md ' >
                        {usertype === '0' ?
                            <form onSubmit={updateadmin} >

                                <Row className='g-4'>
                                    <Col md={4} className='pt-5'>
                                        <div className='text-center'>
                                            {changeimage ? <img
                                                src={imagePreview}
                                                alt="image-preview"
                                                className="rounded-5"
                                                height={250}
                                                style={{ cursor: 'pointer', maxWidth: "300px" }}
                                                onClick={triggerFileInput}

                                            /> : <img
                                                src={form?.image ? `https://smarto.s3.ap-south-1.amazonaws.com/freight/user/${form.image}` : upload} // Use the preview or default image
                                                alt="image-preview"
                                                className="rounded-5"
                                                height={250}
                                                style={{ cursor: 'pointer', maxWidth: "300px",width:'100%' }} // Make the image look clickable
                                                onClick={triggerFileInput}

                                            />}
                                            <input
                                                type="file"
                                                id="fileInput"
                                                style={{ display: 'none' }}
                                                accept="image/*"
                                                name='image'
                                                onChange={handleinput}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={8}>
                                        <Row className='px-3'>

                                            <h3 className='text-blue fw-6 mb-3'>Admin Detail</h3>
                                            <Col md={12}>
                                                <h6 className='text-blue fw-6'>Name</h6>
                                                <input type="text" className='form-control mb-3' placeholder='Enter Name' name="name" value={form.name || ''} onChange={handleinput} />
                                                {/* {console.log(form, 'form')} */}
                                            </Col>
                                            <Col md={6}>
                                                <h6 className='text-blue fw-6'>Email</h6>
                                                <input type="email" className='form-control  mb-3' placeholder='Enter Email' name="email" value={form.email || ''} onChange={handleinput} />
                                            </Col>
                                            <Col md={6}>
                                                <h6 className='text-blue fw-6'>Phone</h6>
                                                <input type="Number" className='form-control  mb-3' placeholder='Enter Phone' name="phone" value={form.phone || ''} onChange={handleinput} />
                                            </Col>

                                            <Col md={12}>
                                                <h6 className='text-blue fw-6'>Address</h6>
                                                <textarea className='form-control' value={form.address || ''} placeholder='Enter Address' name="address" onChange={handleinput}></textarea>
                                            </Col>
                                            <Col md={12}>
                                                <div className="container-fluid d-flex justify-content-end mt-4 px-0" >
                                                    <NavLink to='/' className='btn bg-danger fs-5 fw-6 text-white me-3' >Cancle</NavLink>
                                                    <Button type="submit" className='btn bg-blue fs-5 fw-6 text-white' >Update Admin</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </form> : <form onSubmit={updateuser} >

                                <Row className='g-4'>
                                    <Col md={4} className='pt-5 ps-5'>

                                        <div className='text-center'>
                                            {changeimage2 ? <img
                                                src={imagePreview2}
                                                alt="image-preview"
                                                className="rounded-5"
                                                height={250}
                                                style={{ cursor: 'pointer', maxWidth: "300px" }}
                                                onClick={triggerFileInput}

                                            /> : <img
                                                src={form2.image ? `https://smarto.s3.ap-south-1.amazonaws.com/freight/user/${form2.image}` : upload} // Use the preview or default image
                                                alt="image-preview"
                                                className="rounded-5"
                                                height={250}
                                                style={{ cursor: 'pointer', maxWidth: "300px" }} // Make the image look clickable
                                                onClick={triggerFileInput}

                                            />}
                                            <input
                                                type="file"
                                                id="fileInput"
                                                style={{ display: 'none' }}
                                                accept="image/*"
                                                name='image'
                                                onChange={handleinput2}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={8}>
                                        <Row className='px-3'>
                                            <h3 className='text-blue fw-6 mb-3'>User Detail</h3>
                                            <Col md={12}>
                                                <h6 className='text-blue fw-6'>Name</h6>
                                                <input type="text" className='form-control mb-3' placeholder='Enter Name' name="name" value={form2.name || ''} onChange={handleinput2} />
                                                {/* {console.log(form, 'form')} */}
                                            </Col>
                                            <Col md={6}>
                                                <h6 className='text-blue fw-6'>Email</h6>
                                                <input type="email" className='form-control  mb-3' placeholder='Enter Email' name="email" value={form2.email || ''} onChange={handleinput2} />
                                            </Col>
                                            <Col md={6}>
                                                <h6 className='text-blue fw-6'>Phone</h6>
                                                <input type="Number" className='form-control  mb-3' placeholder='Enter Phone' name="phone" value={form2.phone || ''} onChange={handleinput2} />
                                            </Col>

                                            <Col md={12}>
                                                <h6 className='text-blue fw-6'>Address
                                                    {error ? <span className='text-danger'> ({error}) </span> : ''}
                                                </h6>
                                                <textarea className='form-control' readOnly onFocus={() => setError('To Edit Address contact to Administration')} value={form2.address || ''} placeholder='Enter Address' name="address" onBlur={() => setError(null)} onChange={handleinput2}></textarea>
                                            </Col>
                                            {/* <Col md={12}>
                                            <h3 className='text-blue fw-6 mt-4'>Permission</h3>
                                            <div className="d-flex">
                                                <div className="form-check me-4">
                                                    <input type="checkbox" id="flexCheckParties" name="parties" className="form-check-input" checked={parseInt(form2.parties) === 1 ? true : false} onChange={handleinput2} />
                                                    <label className="form-check-label" htmlFor="flexCheckParties">Parties</label>
                                                </div>
                                                <div className="form-check me-4">
                                                    <input type="checkbox" id="flexCheckTrips" name="trips" className="form-check-input" checked={parseInt(form2.trips) === 1 ? true : false} onChange={handleinput2} />
                                                    <label className="form-check-label" htmlFor="flexCheckTrips">Trips</label>
                                                </div>
                                                <div className="form-check me-4">
                                                    <input type="checkbox" id="flexCheckSuppliers" className="form-check-input" name="supplier" checked={parseInt(form2.supplier) === 1 ? true : false} onChange={handleinput2} />
                                                    <label className="form-check-label" htmlFor="flexCheckSuppliers">Suppliers</label>
                                                </div>
                                                <div className="form-check me-4">
                                                    <input type="checkbox" id="flexCheckDrivers" className="form-check-input" name="drivers" checked={parseInt(form2.drivers) === 1 ? true : false} onChange={handleinput2} />

                                                    <label className="form-check-label" htmlFor="flexCheckDrivers">Drivers</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input type="checkbox" name="trucks" id="flexCheckTrucks" className="form-check-input" checked={parseInt(form2.trucks) === 1 ? true : false} onChange={handleinput2} />
                                                    {console.log(form2.trips, 'truck')}
                                                    <label className="form-check-label" htmlFor="flexCheckTrucks">Trucks</label>
                                                </div>
                                            </div>
                                        </Col> */}
                                            <Col md={12}>
                                                <div className="container-fluid d-flex justify-content-end mt-4 px-0" >
                                                    <NavLink to='/' className='btn bg-danger fs-5 fw-6 text-white me-3' >Cancle</NavLink>
                                                    <Button type="submit" className='btn bg-blue fs-5 fw-6 text-white' >Update </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </form>}

                    </Card>
                </div>
            </Container>

        </div>
    )
}

export default Admin;