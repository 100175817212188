import React, { useEffect, useState } from 'react'
import { Container, Card, Button, Row, Col, Form } from 'react-bootstrap'
import { NavLink, useParams } from 'react-router-dom';
import Sidebar from './sidebar';
import upload from '../assests/upload.svg'
import axios from 'axios';
import Usermenu from './user_menu';
import DOMPurify from 'dompurify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UserEdit() {

    let { id } = useParams();
    const [form, setForm] = useState({})
    const [user, setUser] = useState([])
    const [changeimage, setChangeImage] = useState(false)
    const [service, setservice] = useState([])
    const [imagePreview, setImagePreview] = useState(null);
    const [startroute, setStartRoute] = useState([])


    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
    };


    const handleservice = (e) => {
        const newService = DOMPurify.sanitize(e.target.value);
        setservice((prevService) => {
            const updatedService = prevService ? `${prevService}, ${newService}` : newService;
            setForm((prevForm) => ({
                ...prevForm,
                services: updatedService
            }));
            return updatedService;
        });
    }
    const handleinput = (e) => {
        const { name, files, value, checked } = e.target;

        if (name === 'image' && files && files[0]) {
            const file = files[0];
            setForm({ ...form, [name]: file });
            setChangeImage(true)
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else if (name === 'parties' || name === 'trucks' || name === 'accountant' || name === "delivery" || name === 'supplier' || name === 'is_verified' || name === 'trips' || name === 'drivers' || name === 'partyedit') {
            setForm((prev) => ({
                ...prev,
                [name]: checked ? "1" : "0",
            }));
        } else {
            const sanitizedValue = DOMPurify.sanitize(value);
            setForm({ ...form, [name]: sanitizedValue });
        }
        ////console.log(form);

    }

    const getuser = () => {
        setChangeImage(false)
        axios.get(`https://v4masters.com/backend/public/getuserone/${id}`)

            .then((response) => {
                delete response.data[0].password

                ////console.log("userdata", response.data);
                setForm(response.data[0])
            })
            .catch((error) => {
                //console.log(error)
            })
    }

    const updateuser = (e) => {
        e.preventDefault()
        const newid = toast.loading("Please wait...")
        axios.post(`https://v4masters.com/backend/public/updateuserone/${id}`, form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                toast.update(newid, {
                    render: "User Updated Successfully !",
                    type: "success",
                    isLoading: false,
                    icon: false,
                    autoClose: 2000,
                    closeButton: true,
                    closeOnClick: true,
                });
                delete response.data[0].password

                // setTimeout(() => {
                ////console.log("userdata", response.data);
                setForm(response.data[0])
                getuser()
                // }, 3000);
                // notify()
            })
            .catch((error) => {
                //console.log(error)
                toast.update(newid, {
                    render: "Something Went Wrong !!",
                    type: "error",
                    isLoading: false,
                    icon: false,
                    autoClose: 2000,
                    closeButton: true,
                });
                // notify_error()
            })
    }

    const getStartRoute = (e) => {
        axios.get('https://v4masters.com/backend/public/getstartroute')
            .then((response) => {
                if (response.data === "error") {
                    toast.error('Something went wrong ...')
                }
                else {
                    setStartRoute(response.data)
                }
                //console.log(response.data)
                //console.log(startroute.length, 'start')

            })
            .catch((error) => {

                //console.log(error)
            })
    }


    useEffect(() => {
        getuser();
        getStartRoute()
    }, [])


    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable

                theme="dark"

            />

            <Container fluid className='px-0 vh-100 d-flex'>
                <Sidebar />
                <div className='m-0 p-0 w-100 padding-20'>
                    <Container fluid className='d-flex justify-content-between px-5 mt-4'>
                        <h1 className='text-blue fw-6'>Users</h1>
                        <Usermenu />
                    </Container>
                    <Card className=' mx-5 mt-4 p-4 pb-4 shadow shadow-md ' >
                        {/* {JSON.stringify(form)} */}
                        <form onSubmit={updateuser} >

                            <Row className='g-4'>
                                <Col md={4} className='pt-5 ps-5'>

                                    <div className='text-center'>
                                        {changeimage ? <img
                                            src={imagePreview}
                                            alt="image-preview"
                                            className="rounded-5"
                                            height={250}
                                            style={{ cursor: 'pointer', maxWidth: "300px" }}
                                            onClick={triggerFileInput}

                                        /> : <img
                                            src={form.image ? `https://smarto.s3.ap-south-1.amazonaws.com/freight/user/${form.image}` : upload} // Use the preview or default image
                                            alt="image-preview"
                                            className="rounded-5"
                                            height={250}
                                            style={{ cursor: 'pointer', maxWidth: "300px" }} // Make the image look clickable
                                            onClick={triggerFileInput}

                                        />}
                                        <input
                                            type="file"
                                            id="fileInput"
                                            style={{ display: 'none' }}
                                            accept="image/*"
                                            name='image'
                                            onChange={handleinput}
                                        />
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <Row className='px-3'>
                                        <h3 className='text-blue fw-6 mb-3'>User Detail</h3>
                                        <Col md={12}>
                                            <h6 className='text-blue fw-6'>Name</h6>
                                            <input type="text" className='form-control mb-3' placeholder='Enter Name' name="name" value={form.name} onChange={handleinput} />
                                            {/* {console.log(form, 'form')} */}
                                        </Col>
                                        <Col md={6}>
                                            <h6 className='text-blue fw-6'>Email</h6>
                                            <input type="email" className='form-control  mb-3' placeholder='Enter Email' name="email" value={form.email} onChange={handleinput} />
                                        </Col>
                                        <Col md={6}>
                                            <h6 className='text-blue fw-6'>Phone</h6>
                                            <input type="Number" className='form-control  mb-3' placeholder='Enter Phone' name="phone" value={form.phone} onChange={handleinput} />
                                        </Col>

                                        <Col md={12}>
                                            <h6 className='text-blue fw-6'>Address</h6>
                                            <Form.Select className={`rounded-4 mt-1`} required name="address" onChange={handleinput} value={form.address} >
                                                <option value=''>Select Branch Address</option>
                                                {startroute ? startroute.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.title}>
                                                            {item.title}
                                                        </option>
                                                    );
                                                }) : ''}

                                            </Form.Select>
                                            {/* <textarea className='form-control' value={form.address} placeholder='Enter Address' name="address" onChange={handleinput} ></textarea> */}
                                        </Col>
                                        <Col md={12}>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" name='is_verified' role="switch" id="flexSwitchCheckChecked" checked={parseInt(form.is_verified) === 1 ? true : false} onChange={handleinput} />
                                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">User verified</label>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <h3 className='text-blue fw-6 mt-4'>Permission</h3>
                                            <div className="d-flex">

                                                <div className="form-check me-4">
                                                    <input type="checkbox" id="flexCheckParties" name="parties" className="form-check-input" checked={parseInt(form.parties) === 1 ? true : false} onChange={handleinput} />
                                                    <label className="form-check-label" htmlFor="flexCheckParties">Parties</label>
                                                </div>
                                                <div className="form-check me-4">
                                                    <input type="checkbox" id="flexCheckSuppliers" className="form-check-input" name="supplier" checked={parseInt(form.supplier) === 1 ? true : false} onChange={handleinput} />
                                                    <label className="form-check-label" htmlFor="flexCheckSuppliers">Suppliers</label>
                                                </div>
                                                <div className="form-check me-4">
                                                    <input type="checkbox" id="flexCheckDrivers" className="form-check-input" name="drivers" checked={parseInt(form.drivers) === 1 ? true : false} onChange={handleinput} />

                                                    <label className="form-check-label" htmlFor="flexCheckDrivers">Drivers</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input type="checkbox" name="trucks" id="flexCheckTrucks" className="form-check-input" checked={parseInt(form.trucks) === 1 ? true : false} onChange={handleinput} />
                                                    {/* {console.log(form.trips, 'truck')} */}
                                                    <label className="form-check-label" htmlFor="flexCheckTrucks">Trucks</label>
                                                </div>

                                                <div className="form-check me-4">
                                                    <input type="checkbox" id="flexCheckTrips" name="trips" className="form-check-input" checked={parseInt(form.trips) === 1 ? true : false} onChange={handleinput} />
                                                    <label className="form-check-label" htmlFor="flexCheckTrips">Bilty Edit</label>
                                                </div>

                                            </div>
                                            <div className="d-flex">
                                                <div className="form-check me-3">
                                                    <input type="checkbox" id="flexCheckTrucks" name="partyedit" className="form-check-input" checked={parseInt(form.partyedit) === 1 ? true : false} onClick={handleinput} />
                                                    <label className="form-check-label" htmlFor="flexCheckTrucks">Party Edit</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input type="checkbox" id="flexCheckDelivery" name="delivery" className="form-check-input" checked={parseInt(form.delivery) === 1 ? true : false} onClick={handleinput} />
                                                    <label className="form-check-label" htmlFor="flexCheckDelivery">Delivery </label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input type="checkbox" id="flexCheckDelivery" name="accountant" className="form-check-input" checked={parseInt(form.accountant) === 1 ? true : false} onClick={handleinput} />
                                                    <label className="form-check-label" htmlFor="flexCheckDelivery">Account Manager </label>
                                                </div>
                                            </div>

                                        </Col>
                                        <Col md={12}>
                                            <div className="container-fluid d-flex justify-content-end mt-4 px-0" >
                                                <NavLink to='/' className='btn bg-danger fs-5 fw-6 text-white me-3' >Cancle</NavLink>
                                                <Button type="submit" className='btn bg-blue fs-5 fw-6 text-white' >Update</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </form>
                    </Card>
                </div>
            </Container>

        </div>
    )
}

export default UserEdit;