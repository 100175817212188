import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import user_logo from '../assests/user-logo.svg'
import AddminLogo from '../assests/admin_setting.svg'
import Rupee from '../assests/rupee.svg';
import Notification from '../assests/notification.svg';
import Logout from '../assests/logout.svg';
import axios from 'axios';
import { useAuth } from './AuthContext';


function Usermenu() {
  const nevigate = useNavigate()
  const { token, setToken, userData, setUserData, validateToken, isAuth, setIsAuth } = useAuth()
  const user_type = sessionStorage.getItem('user_type')
  const userid = sessionStorage.getItem('id')
  const [user, setUser] = useState([]);
  const [admin, setAdmin] = useState([]);

  const getuser = async () => {
    const store = await axios.get(`https://v4masters.com/backend/public/getuserone/${userid}`)

      .then((response) => {
        delete response.data[0].password

       //console.log("userdata", response.data);
        setUser(response.data[0])
      })
      .catch((error) => {
       //console.log(error)
      })
  }
  const getadmin = () => {
    axios.get(`https://v4masters.com/backend/public/getadminone/${userid}`)
      .then((response) => {
        delete response.data[0].password

       //console.log("userdata", response.data);
        setAdmin(response.data[0])
      })
      .catch((error) => {
       //console.log(error)
      })
  }
  const handleLogout = () => {
    sessionStorage.clear();
    setToken(null)
    setUserData(null)
    setIsAuth(false)
    nevigate('/login');

  }

  useEffect(() => {
    getadmin();
    getuser();
  }, [])
  return (

    <>
      <div className="dropdown">
        {/* <img src={user_logo} alt="" type="button" className='rounded-5 btn' data-bs-toggle="dropdown" height={90} aria-expanded="false" /> */}
        {user_type === '0' && userData?.image ? <img src={`https://smarto.s3.ap-south-1.amazonaws.com/freight/user/${userData.image}`} type="button" className='rounded-5 btn' data-bs-toggle="dropdown" height={70} aria-expanded="false" /> : user_type === '1' && user.image ? <img src={`https://smarto.s3.ap-south-1.amazonaws.com/freight/user/${user.image}`} type="button" className='rounded-5 btn' data-bs-toggle="dropdown" height={70} aria-expanded="false" /> : <img src={`https://smarto.s3.ap-south-1.amazonaws.com/freight/user/dummy.webp`} alt='image--' className='ps-1 me-2 rounded-4' data-bs-toggle="dropdown" aria-expanded="false" height={60} />}
        {/* <div className='dropdown-menu'> */}
        <div className="dropdown-menu user_menu_dropdown">
          <div className='d-flex align-items-center'>
            {user_type === '0' && userData?.image ? <img src={`https://smarto.s3.ap-south-1.amazonaws.com/freight/user/${userData.image}`} alt='' className='ps-1 me-2' height={60} /> : user_type === '1' && user.image ? <img src={`https://smarto.s3.ap-south-1.amazonaws.com/freight/user/${user.image}`} alt='image--' className='ps-1 me-2' height={60} /> : <img src={`https://smarto.s3.ap-south-1.amazonaws.com/freight/user/dummy.webp`} alt='image--' className='ps-1 me-2 rounded-4' height={60} />}

            <div className='d-flex flex-column '>
              {user_type === '0' ? <><h4 className='mb-0 fs-menu'>{userData?.name}</h4>
                <h6 className='fs-3'>Admin No. {userData?.phone}</h6></> : <><h4 className='mb-0 fs-menu'>{userData?.name}</h4>
                <h6 className='fs-3'>User No. {userData?.phone}</h6></>}

            </div>
          </div>
          <ul className=" rounded-4 ">
            {user_type === '0' ? <li className='d-flex align-items-center li_menu'>
              <img src={AddminLogo} alt='image--' className='' height={23} />
              <NavLink to={'/setting'} className='btn text-dark fs-5 ps-4 fw-6'>Admin Setting</NavLink>
            </li> : <li className='d-flex align-items-center li_menu'>
              <img src={AddminLogo} alt='image--' className='' height={23} />
              <NavLink to={'/setting'} className='btn text-dark fs-5 ps-4 fw-6'>User Setting</NavLink>
            </li>}

            <li className='d-flex align-items-center li_menu'>
              <img src={Rupee} alt='image--' className='px-1' height={20} />
              <NavLink to={'/'} className='btn text-dark fs-5 ps-4 fw-6'>Change Password</NavLink>
            </li>
            <li className='d-flex align-items-center li_menu'>
              <img src={Notification} alt='image--' className='' height={20} />
              <NavLink to={'/'} className='btn text-dark fs-5 ps-4 fw-6'>Notification</NavLink>
            </li>

            <li className='d-flex align-items-center li_menu '>
              <img src={Logout} alt='image--' className='' height={19} />
              <button className='btn fs-5 ps-4 fw-6' onClick={handleLogout}>Log Out</button>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Usermenu;