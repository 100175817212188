import React, { useEffect, useState } from 'react'
import { Container, Card, Button, InputGroup, Form, Row, Col, Modal } from 'react-bootstrap'
import { NavLink, useParams } from 'react-router-dom';
import Sidebar from './sidebar';
import user_logo from '../assests/user-logo.svg'
import user_img from '../assests/user_image.svg'
import { BsSearch } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEye } from "react-icons/fa";
import { IoIosAddCircleOutline } from "react-icons/io";
import SidebarMob from './siderbar_mobile';
import { useNavigate } from 'react-router-dom';
import Usermenu from './user_menu';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import html2pdf from 'html2pdf.js';

function Passbook() {
    const nevigate = useNavigate()
    var { id } = useParams()


    const [partyname, setPartyname] = useState('')
    const generatePDF = () => {
        const element = document.getElementById('targetLR');
        if (!element) {
            return;
        }

        const opt = {
            margin: 0.1, // Adjust margins as needed
            filename: `${partyname || ''}(passbook).pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 3, logging: true }, // Increase scale for better quality
            jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape', pageBreak: { mode: 'avoid-all' } }, // Avoid breaking content if possible
            pagebreak: { mode: 'avoid-all' } // Avoid breaking content if possible
        };
        html2pdf().from(element).set(opt).save();
    };
    const [show, setShow] = useState(false);
    const [data, setData] = useState({})
    const [passbook, setPassbook] = useState([])
    const [collection, setCollection] = useState(false);

    const handleClosecollection = () => setCollection(false);
    const handlecollection = () => setCollection(true);
    const handleShow = () => setShow(true);

    const handlemonth = () => {
        nevigate('/montlybal')
    }
    const handletrip = () => {
        nevigate(`/partiesuser/${id}`)
    }
    const exportToExcel = () => {
        // Get the HTML table element
        const table = document.getElementById('data-table');

        // Convert HTML table to a worksheet
        const ws = XLSX.utils.table_to_sheet(table);

        // Create a new workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Generate Excel file as binary string
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        // Convert binary string to blob
        const buf = new ArrayBuffer(wbout.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < wbout.length; i++) {
            view[i] = wbout.charCodeAt(i) & 0xFF;
        }
        const blob = new Blob([buf], { type: 'application/octet-stream' });

        // Trigger download
        // staff.staff_name ? staff.staff_name :
        saveAs(blob, `${'data'}.xlsx`);
    };
    const getparty = () => {
        axios.post(`https://v4masters.com/backend/public/getbuiltyparty/${id}`)
            .then((response) => {
                //console.log("getbuiltyone", response.data)
                var balance = 0;
                for (let i = 0; i < response.data.length; i++) {

                    balance += parseInt(response.data[i].party_trip_balance)
                }
                //console.log("balance", balance)
                setData({
                    'balance': balance,
                    'trips': response.data.length
                })
                // SetUser(response.data)
            })
            .catch((error) => {
                //console.log("error", error)
            })
    }
    const getpartypassbook = () => {
        axios.get(`https://v4masters.com/backend/public/getbuiltypassbook/${id}`)
            .then((response) => {
                //console.log("getbuiltyonrpasasaa", response.data)
                if (response.data === 'error') {

                    setPassbook([]);
                } else {
                    setPassbook(response.data);
                }
            })
            .catch((error) => {
                //console.log("error", error)
            })
    }
    const [parties, setParties] = useState([]);
    const getparties = async () => {
        await axios.get('https://v4masters.com/backend/public/getparties')
            .then((response) => {
                if (response.data === 'error') {
                    setParties([])
                }
                else
                    setParties(response.data)
            })
            .catch((error) => {
                //console.log(error);
            })
    }
    const [user, SetUser] = useState([]);
    const getbiltyparty = () => {
        axios.post(`https://v4masters.com/backend/public/getbuiltyparty/${id}`)
            .then((response) => {
                //console.log("getbuiltyone",response.data)
                if (response.data === "error") {
                    SetUser([])
                } else {

                    var balance = 0;
                    var trips = 0;
                    for (let i = 0; i < response.data.length; i++) {

                        balance += parseInt(response.data[i].party_trip_balance)
                        //console.log(response.data[i].status , 'status')
                        if (response.data[i].status == 1) {
                            trips += 1;
                        }
                    }
                    setData({
                        'balance': balance,
                        'trips': trips,
                    })
                    SetUser(response.data)
                }
            })
            .catch((error) => {
                //console.log("error",error)
            })
    }
    useEffect(() => {
        getparty();
        getpartypassbook();
        getparties()
        getbiltyparty()
        const partyname2 = parties ? parties.find(partyItem => partyItem.id === user[0]?.party)?.name || "" : ''
        setPartyname(partyname2)
    }, [])
    return (
        <div>
            {/* {JSON.stringify(partyname)} */}
            <Container fluid className='px-0 vh-100 d-flex'>
                <Sidebar />
                <SidebarMob />
                <div className='m-0 p-0 w-100 padding-20'>
                    <Container fluid className='d-flex justify-content-between px-5 mt-4 align-items-center'>
                        <h1 className='text-blue fw-6'>{''}</h1>
                        {/* <h1 className='text-blue fw-6'>{parties ? parties.find(partyItem => partyItem.id === user[0]?.party)?.name || "" : ''}</h1> */}

                        <div className="d-flex justify-content-end align-items-center">
                            {/* <Button className='btn bg-blue fs-5 fw-6 text-white me-3 h-50 rounded-4' onClick={handleShow}><IoIosAddCircleOutline className='me-2 fs-4 mb-1' />Add Payment</Button> */}
                            <NavLink to='/useradd' className='btn bg-black fs-5 fw-6 text-white me-3 h-50 rounded-4'><IoIosAddCircleOutline className='me-2 fs-4 mb-1' />Add Trip</NavLink>

                            <Usermenu />
                        </div>
                    </Container>
                    <Card className='d-flex justify-content-between  mt-4 align-items-center mx-5 flex-row shadow shadow-sm rounded-4 px-2 py-4'>
                        <div className="d-flex">
                            <Button to='/useradd' className=' bg-white fs-5 fw-6 text-blue me-3 border border-dark  h-50 rounded-4' onClick={handletrip}>Trips</Button>
                            <Button to='/useradd' className=' bg-blue fs-5 fw-6 text-white me-3 h-50 rounded-4'>Passbook</Button>
                            {/* <Button to='/useradd' className=' bg-white fs-5 fw-6 text-blue border border-dark me-3 h-50 rounded-4' onClick={handlemonth}>Monthly Balance</Button>
                            <Button to='/useradd' className=' bg-white fs-5 fw-6 text-blue border border-dark me-3 h-50 rounded-4'>Party Details</Button> */}
                        </div>


                        <div className="d-flex">
                            <Button to='/useradd' className=' bg-white fs-5 fw-6 text-dark border border-dark ms-4 me-2 h-50 rounded-4'>Total Party Balance : <span className='text-blue ms-2'> {data.balance} Rs</span></Button>
                            <Button to='/useradd' className=' bg-white fs-5 fw-6 text-dark border border-dark  h-50 rounded-4 me-2'>Total Active Trips : {data.trips}</Button>
                            <Button to='/useradd' className='bg-blue fs-5 fw-6 text-white border border-dark  h-50 rounded-4' onClick={handlecollection}>Collection Reminder</Button>
                        </div>


                    </Card>
                    <Card className=' mx-5 mt-2 pb-4 shadow shadow-md overflowy' style={{ maxHeight: "67vh" }}>
                        <div className='d-flex justify-content-between align-items-center mt-2 pe-3 '>
                            {/* <div className="d-flex">
                                <Button className=' bg-white fs-5 fw-6 text-dark border border-dark ms-4 me-2 h-50 rounded-4'>Total Party Balance :<span className='text-blue ms-2'> {data.balance}</span></Button>
                                <Button className=' bg-white fs-5 fw-6 text-dark border border-dark  h-50 rounded-4 me-2'>Total Active Trips : {data.trips}</Button>
                                <Button className=' bg-white fs-5 fw-6 text-dark border border-dark me-2 h-50 rounded-4'>Trip Balance <span className='text-blue ms-2'> $37,0258</span></Button>

                            </div> */}
                            <div className="d-flex pe-3 mt-2">
                                <div className='dropdown border border-dark rounded-4 ms-4 me-2'>
                                    <Button className='bg-white fs-5 fw-6 text-dark  h-50  dropdown-toggle p-2' type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">All Months</Button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className="dropdown-item text-blue fs-5 fw-6" href="#">Date</a></li>
                                        <li><a className="dropdown-item" href="#">Today</a></li>
                                        <li><a className="dropdown-item" href="#">This Week</a></li>
                                        <li><a className="dropdown-item" href="#">Last Week</a></li>
                                        <li><a className="dropdown-item" href="#">This Month</a></li>
                                        <li><a className="dropdown-item" href="#">This Year</a></li>
                                        <li><a className="dropdown-item" href="#">All Months</a></li>
                                    </ul>
                                </div>
                                <div className='dropdown border border-dark rounded-4'>
                                    <Button to='/useradd' className=' bg-white fs-5 fw-6 text-dark  h-50 dropdown-toggle' type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">All Trips</Button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className="dropdown-item text-blue fs-5 fw-6" href="#">Trips</a></li>
                                        <li><a className="dropdown-item" href="#">All Trips</a></li>
                                        <li><a className="dropdown-item" href="#">Due Trips</a></li>
                                        <li><a className="dropdown-item" href="#">Settled Trips</a></li>
                                        <li><a className="dropdown-item" href="#">In Progress</a></li>
                                        <li><a className="dropdown-item" href="#">Active</a></li>

                                    </ul>
                                </div>


                            </div>
                            <div className='d-flex me-2 align-items-center mt-2'>


                                <Button className=' bg-danger fs-5 fw-6 text-white me-3 rounded-4 ' onClick={generatePDF}>Download</Button>

                                <InputGroup className="">
                                    <Form.Control
                                        placeholder="Search.."
                                        aria-label="search"
                                        aria-describedby="basic-addon2"
                                    />
                                    <InputGroup.Text id="basic-addon2"><BsSearch /></InputGroup.Text>
                                </InputGroup>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center  border-bottom pb-3'>
                            {/* <Button className=' bg-white fs-5 fw-6 text-dark border border-dark ms-4 me-2 h-50 rounded-4'>Trip Balance <span className='text-blue ms-2'> $37,0258</span></Button> */}

                        </div>

                        <div className="table-responsive px-2 pt-4" id='targetLR'>
                            <div className=''>Party Name : {partyname}</div>
                            <table className='table table-striped  table-hover' id="data-table">
                                <thead>
                                    <tr>
                                        <th><h4>#</h4></th>
                                        <th><h4>Builty Id</h4></th>
                                        <th><h4>Amount Added</h4></th>
                                        <th><h4>Amount Paid</h4></th>
                                        <th><h4>Payment Date</h4></th>
                                        <th><h4>Payment Method</h4></th>
                                        <th><h4>Party Balance</h4></th>
                                        <th><h4>Remark </h4></th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {passbook.length > 0 ? passbook.slice().reverse().map((item, index) => (

                                        <tr className='pt-4' key={index}>
                                            <td ><h5 className='mt-2'>{index + 1}</h5></td>
                                            <td><h5 className='mt-2'>{item.bilty_id}</h5></td>
                                            <td><h5 className='mt-2' >{item.payment_amount_added ? item?.payment_amount_added : '-'}</h5></td>
                                            <td><h5 className='mt-2'>{item.payment_amount_paid ? item?.payment_amount_paid : '-'}</h5></td>
                                            <td><h5 className='mt-2'>{item.payment_date}</h5></td>
                                            <td><h5 className='mt-2'>{item.payment_method}</h5></td>
                                            <td><h5 className='btn text-blue '>{item.balance}</h5></td>
                                            <td><h5 className='btn text-blue '>{item.remark}</h5></td>
                                        </tr>
                                    )) : <tr className='pt-4 text-center' >

                                        <td colSpan={7}><h5 className='mt-2'>No Record Found</h5></td>

                                    </tr>}

                                </tbody>
                            </table>
                        </div>

                    </Card>
                </div>
            </Container>

        </div>
    )
}

export default Passbook;