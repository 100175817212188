import React, { useState } from 'react';
import logo from '../assests/new3/logo_blue.svg';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from 'axios';

function ForgetPass() {

    const [sendOTP, setSendOTP] = useState(false);
    const [verifyOTP, setVerifyOTP] = useState(false);
    const [phoneno, setPhoneno] = useState({
        user_type: '',
        phone: '',
        otp: ''
    });
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const handleinput = (e) => {
        setPhoneno({
            ...phoneno,
            [e.target.name]: e.target.value, 'id': resp?.url
        })
        setErrors({
            ...errors,
            [e.target.name]: '',
        })

    }
    const [errors, setErrors] = useState()
    function validateLoginForm(phoneno) {
        const newErrors = {};
        let checkIncomplete = false;

        if (!phoneno.user_type || phoneno.user_type.trim() === '') {
            newErrors.user_type = 'Name is required';
            checkIncomplete = true;
        } else {
            newErrors.login_type = '';
        }

        // Validate mobile number
        if (!phoneno.phone || phoneno.phone.trim() === '') {
            newErrors.phone = 'Mobile number is required';
            checkIncomplete = true;
        } else {
            newErrors.phone = '';
        }
       //console.log('Validation errors:', newErrors);
        setErrors(prevErrors => ({
            ...prevErrors,
            ...newErrors
        }));

        return checkIncomplete;
    }
    function validateOtpForm(phoneno) {
        const newErrors = {};
        let checkIncomplete = false;

        if (!phoneno.otp || phoneno.otp.trim() === '') {
            newErrors.otp = 'Name is required';
            checkIncomplete = true;
        } else {
            newErrors.otp = '';
        }

       //console.log('Validation errors:', newErrors);
        setErrors(prevErrors => ({
            ...prevErrors,
            ...newErrors
        }));

        return checkIncomplete;
    }
    const navigate = useNavigate();

    const handleContinue = () => {
        const newid = toast.loading("Please wait...");

        toast.update(newid, {
            render: "Redirecting to LOGIN PAGE !",
            type: "success",
            isLoading: false,
            icon: true,
            autoClose: 3000,
            closeButton: true,
            closeOnClick: true,
            onClose: () => {
                navigate('/login');
            }
        });
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };
    const [resp, setResp] = useState([])
    const handleSendOTP = () => {
        const isValid = validateLoginForm(phoneno)
       //console.log(isValid, 'valid')
        if (!isValid) {
            const newid = toast.loading("Please wait...");
            axios.post('https://v4masters.com/backend/public/forgot_password_sendOtp', phoneno)
                .then((response) => {
                   //console.log(response.data, "login")
                    if (response.data === "error") {
                        setTimeout(() => {
                            toast.update(newid, {
                                render: "Something went wrong",
                                type: "error",
                                isLoading: false,
                                icon: true,
                                autoClose: 3000,
                                closeButton: true,
                                closeOnClick: true,
                            })
                        }, 1000);

                    } else {
                        setResp(response.data)
                        toast.update(newid, {
                            render: "Sending OTP!",
                            type: "success",
                            isLoading: false,
                            icon: true,
                            autoClose: 3000,
                            closeButton: true,
                            closeOnClick: true,
                            onClose: () => {
                                setSendOTP(true);
                               //console.log('sendOTP');
                            }
                        });
                    }

                })
                .catch((error) => {
                    toast.update(newid, {
                        render: "Something went wrong",
                        type: "error",
                        isLoading: false,
                        icon: true,
                        autoClose: 3000,
                        closeButton: true,
                        closeOnClick: true,
                    })
                   //console.log(error)
                })

        }
    }

    const handleVerifyOTP = () => {
        const newid = toast.loading("Please wait...");
        // const isValid = validateOtpForm(phoneno)
        ////console.log(isValid, 'valid')
        // if (!isValid) {
        //     const newid = toast.loading("Please wait...");
        //     axios.post('https://v4masters.com/backend/public/forgot_password_verifyOtp', phoneno)
        //         .then((response) => {
        //             if (response.data === "error") {
        //                 setTimeout(() => {
        //                     toast.update(newid, {
        //                         render: "Something went wrong",
        //                         type: "error",
        //                         isLoading: false,
        //                         icon: true,
        //                         autoClose: 3000,
        //                         closeButton: true,
        //                         closeOnClick: true,
        //                     })
        //                 }, 1000);

        //             } else {
        //                 toast.update(newid, {
        //                     render: "Sending OTP!",
        //                     type: "success",
        //                     isLoading: false,
        //                     icon: true,
        //                     autoClose: 3000,
        //                     closeButton: true,
        //                     closeOnClick: true,
        //                     onClose: () => {
        //                         setSendOTP(true);
        //                        //console.log('sendOTP');
        //                     }
        //                 });
        //             }

        //         })
        //         .catch((error) => {
        //             toast.update(newid, {
        //                 render: "Something went wrong",
        //                 type: "error",
        //                 isLoading: false,
        //                 icon: true,
        //                 autoClose: 3000,
        //                 closeButton: true,
        //                 closeOnClick: true,
        //             })
        //            //console.log(error)
        //         })

        // }
        toast.update(newid, {
            render: "Verifying OTP!",
            type: "success",
            isLoading: false,
            icon: true,
            autoClose: 3000,
            closeButton: true,
            closeOnClick: true,
            onClose: () => {
                setVerifyOTP(true);
               //console.log('verifyOTP');
            }
        });
    }

    return (
        <div className="login_bg_image">
            {/* {JSON.stringify(resp?.url)} */}
            <ToastContainer />
            <Container fluid>
                <Row className="py-0">
                    <Col md={7}></Col>
                    <Col md={5} className="align-middle">
                        <div className={`p-3 full_height card-container`}>
                            <div className={`h-100 bg-white d-flex row justify-content-center align-items-center p-5 login_form rounded-4 card-content`}>
                                <div className="text-center">
                                    <img src={logo} width={200} alt="Freight" />
                                    <h2 className="text-dark fw-5 mt-4">
                                        Forget Password
                                    </h2>
                                    <div className="mt-5">
                                        <select className={`form-control mb-3 border  rounded-4 ${errors?.user_type ? 'border-danger' : 'border-transparent'}`} onChange={handleinput} name="user_type" >
                                            <option defaultValue='' disabled selected >Signup Type</option>
                                            <option value="0">Admin</option>
                                            <option value="1">User</option>
                                        </select>
                                        <input
                                            type="number"
                                            className={`form-control mb-3 rounded-4 ${errors?.phone ? 'border-danger' : 'border-transparent'} `}
                                            readOnly={sendOTP}
                                            value={phoneno?.phone || ''}
                                            onChange={handleinput}
                                            name="phone"
                                            placeholder="mobile number"
                                        />
                                        <Button className={`btn bg-blue text-white w-100 rounded-4 border-0 ${sendOTP ? 'd-none' : ''}`} onClick={handleSendOTP}>
                                            Send OTP
                                        </Button>

                                        <input
                                            type="number"
                                            className={`form-control mb-3 rounded-4 ${sendOTP ? '' : 'd-none'} ${verifyOTP ? 'd-none' : ''}`}
                                            name="otp"
                                            max={4}
                                            maxLength={4}
                                            placeholder="enter otp"
                                        />
                                        <Button className={`btn bg-blue text-white w-100 rounded-4 border-0 ${sendOTP ? '' : 'd-none'} ${verifyOTP ? 'd-none' : ''}`} onClick={handleVerifyOTP}>
                                            Verify OTP
                                        </Button>

                                        {/* <input
                                            type="password"
                                            className={`form-control mb-3 rounded-4 ${verifyOTP ? '' : 'd-none'}`}
                                            name="password"
                                            placeholder="enter password"
                                        /> */}

                                        {/* Password Field */}
                                        <div className={`input-group mb-3 ${verifyOTP ? '' : 'd-none'}`}>
                                            <input
                                                type={passwordVisible ? "text" : "password"}
                                                className={`form-control rounded-4`}
                                                name="password"
                                                placeholder="Enter password"
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text" onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                                                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                                </span>
                                            </div>
                                        </div>

                                        {/* Confirm Password Field */}
                                        <div className={`input-group mb-3 ${verifyOTP ? '' : 'd-none'}`}>
                                            <input
                                                type={confirmPasswordVisible ? "text" : "password"}
                                                className={`form-control rounded-4`}
                                                name="confirmPassword"
                                                placeholder="Confirm password"
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text" onClick={toggleConfirmPasswordVisibility} style={{ cursor: 'pointer' }}>
                                                    {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                                                </span>
                                            </div>
                                        </div>
                                        <Button className={`btn bg-blue text-white w-100 rounded-4 border-0 ${verifyOTP ? '' : 'd-none'}`} onClick={handleContinue}>
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ForgetPass;