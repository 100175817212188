import React, { useEffect, useState } from 'react'
import { Container, Card, Button, InputGroup, Form, Row, Col, Modal } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import Sidebar from './sidebar';
import user_logo from '../assests/user-logo.svg'
import { BsSearch } from "react-icons/bs";
import { IoIosAddCircleOutline } from "react-icons/io";
import SidebarMob from './siderbar_mobile';
import { useNavigate } from 'react-router-dom';
import Usermenu from './user_menu';
import DOMPurify from 'dompurify';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import bdot from '../assests/bluedot.svg';
import gdot from '../assests/greendot.svg';

function Driver() {
    const userid = sessionStorage.getItem('id')
    const nevigate = useNavigate()
    const [search, setSearch] = useState('')

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [modal, setmodal] = useState(false);
    const [form, setForm] = useState({ 'userid': userid });
    const [driver, setDriver] = useState([]);
    const [amount, setAmount] = useState({ 'transaction_type': "" });


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => {
        setShow2(true);
        setShow(false);
    }
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => {
        setShow3(true);
        setShow(false);
    }
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => {
        setShow4(true);
    }

    const handlemodal = (id) => {
       //console.log(id);
        if (id == 1) {
            setShow(true);
            setmodal(true)
            setAmount({
                "transaction_type": "0",
            })
        } else {
            setShow(true);
            setmodal(false)
            setAmount({
                "transaction_type": "1",
            })
        }
    }

    const handleuser = (id) => {
        nevigate(`/driverone/${id}`)
    }

    const handleinput = (e) => {

        const sanitizedValue = DOMPurify.sanitize(e.target.value);

        setForm({
            ...form,
            [e.target.name]: sanitizedValue,
        })

    }
    const handlechange = (e, id) => {
       //console.log("testing input change")

        const sanitizedValue = DOMPurify.sanitize(e.target.value);
        setAmount({
            ...amount,
            [e.target.name]: sanitizedValue,

        })



    }
    const handleSubmit = (e) => {
        e.preventDefault()
        const newid = toast.loading("Please wait...")
        setShow4(false)
        axios.post('https://v4masters.com/backend/public/adddriver', form)
            .then((response) => {
                if (response.data === "success") {
                    // setTimeout(() => {
                    toast.update(newid, {
                        render: "Driver Added Successfully !",
                        type: "success",
                        isLoading: false,
                        icon: true,
                        autoClose: 3000,
                        closeButton: true,
                        closeOnClick: true,
                    })
                    // }, 1000);

                    // notify()
                    setTimeout(() => {
                        nevigate("/driver");
                    }, 3000)
                } else {
                    setTimeout(() => {
                        toast.update(newid, {
                            render: "Something went wrong !!",
                            type: "error",
                            isLoading: false,
                            icon: true,
                            autoClose: 3000,
                            closeButton: true,
                            closeOnClick: true,
                        })
                    }, 1000);
                    // notify_error()
                }

            })
            .catch((error) => {

               //console.log(error)
            })
    }
    const handlegave = (e) => {
        e.preventDefault()
        setShow2(false)
        setShow3(false)
        const newid = toast.loading("Please wait...")
        axios.post('https://v4masters.com/backend/public/adddriveramount', amount)
            .then((response) => {
                if (response.data === "success") {
                    // setTimeout(() => {
                    toast.update(newid, {
                        render: " Updated Successfully !",
                        type: "success",
                        isLoading: false,
                        icon: true,
                        autoClose: 3000,
                        closeButton: true,
                        closeOnClick: true,
                    })

                } else {
                    setTimeout(() => {
                        toast.update(newid, {
                            render: "Something went wrong !!",
                            type: "error",
                            isLoading: false,
                            icon: true,
                            autoClose: 3000,
                            closeButton: true,
                            closeOnClick: true,
                        })
                    }, 1000);
                    // notify_error()
                }

            })
            .catch((error) => {

               //console.log(error)
            })
    }
    // const getDriver = (e) => {
    //     axios.get('https://v4masters.com/backend/public/getdriver')
    //         .then((response) => {
    //            //console.log("driver", response.data)
    //             if(response.data==="error"){
    //                 setDriver([])
    //             }else{

    //                 setDriver(response.data)
    //             }
    //         })
    //         .catch((error) => {
    //            //console.log(error)
    //         })
    // }
    const getDriver = (e) => {
        axios.get(`https://v4masters.com/backend/public/getdriver/`)
            .then((response) => {
               //console.log("driver", response.data)
                if (response.data === "error") {
                    setDriver([])
                } else {

                    setDriver(response.data)
                }
            })
            .catch((error) => {
               //console.log(error)
            })
    }
    // const getDriver = (e) => {
    //     axios.get(`https://v4masters.com/backend/public/getdriverdetail/${userid}`)
    //         .then((response) => {
    //            //console.log("driver", response.data)
    //             if(response.data==="error"){
    //                 setDriver([])
    //             }else{

    //                 setDriver(response.data)
    //             }
    //         })
    //         .catch((error) => {
    //            //console.log(error)
    //         })
    // }
    useEffect(() => {

        getDriver();
    }, [])
    const filteredUsers = driver.filter(user => {
        // Convert the search term to lower case once for efficiency
        const lowerCaseSearch = search.toLowerCase();

        // Check if user properties are defined and convert to lower case before checking inclusion
        const truck_no = user.truck_no ? user.truck_no.toLowerCase() : '';
        const phone = user.phone ? user.phone.toLowerCase() : '';
        const name = user.name ? user.name.toLowerCase() : '';
        const startLoc = user.start_loc ? user.start_loc.toLowerCase() : '';
        const destination = user.destination ? user.destination.toLowerCase() : '';
        return (

            truck_no.includes(lowerCaseSearch) ||
            phone.includes(lowerCaseSearch) ||
            name.includes(lowerCaseSearch) ||
            destination.includes(lowerCaseSearch) ||
            startLoc.includes(lowerCaseSearch)
        );
    });
    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                
                theme="dark"

            />
            <Container fluid className='px-0 vh-100 d-flex'>
                <Sidebar />
                <SidebarMob />
                <div className='m-0 p-0 w-100 padding-20'>
                    <Container fluid className='d-flex justify-content-between px-5 mt-4 align-items-center'>
                        <h1 className='text-blue fw-6'>Drivers</h1>

                        <div className="d-flex justify-content-end align-items-center">
                            {/* <Button className='btn bg-danger fs-5 fw-6 text-white me-3 h-50 rounded-4' onClick={() => { handlemodal(1) }}>Driver Gave</Button>
                            <Button className='btn bg-cyan fs-5 fw-6 text-white me-3 h-50 rounded-4' onClick={() => { handlemodal(2) }}>Driver Got</Button> */}
                            {/* <button className='btn bg-blue fs-5 fw-6 text-white me-3 h-50 rounded-4' onClick={handleShow4}><IoIosAddCircleOutline className='me-2 fs-4 mb-1' />Add Driver</button> */}
                            <Usermenu />
                        </div>
                    </Container>

                    <Card className=' mx-5 mt-4 pb-4 shadow shadow-md ' >
                        <div className='d-flex justify-content-end align-items-center mt-4 pe-3 border-bottom '>
                            {/* <div className="d-flex">
                                <NavLink className='text-decoration-none border border-1 border-dark fs-5 fw-6 text-dark me-3 h-50 rounded-4 p-2 ms-2'>Total Party Balance <span className='text-blue ms-2'>37,50,750</span></NavLink>


                            </div> */}
                            <div className='text-end'>
                                <InputGroup className="mb-3 mt-3">
                                    <Form.Control
                                        placeholder="Search.."
                                        aria-label="search"
                                        value={search}
                                        onChange={e => setSearch(e.target.value)}
                                        aria-describedby="basic-addon2"
                                    />
                                    <InputGroup.Text id="basic-addon2"><BsSearch /></InputGroup.Text>
                                </InputGroup>
                            </div>
                        </div>
                        <div className="table-responsive px-2 pt-4">

                            <table className='table table-striped  table-hover'>
                                <thead>
                                    <tr>
                                        <th><h4>#</h4></th>
                                        <th><h4>Driver Name</h4></th>
                                        <th><h4>Mobile Number</h4></th>
                                        <th><h4>Status</h4></th>
                                        <th className='text-center'><h4>Truck No</h4></th>
                                        <th className='text-center'><h4>Route</h4></th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {driver.length > 0 ? filteredUsers.map((item, index) => (
                                        <tr className='pt-4' key={index}>
                                            <td ><h5 className='mt-2'>{index + 1}</h5></td>
                                            <td><h5 className='mt-2'>{item.name}</h5></td>
                                            <td><h5 className='mt-2'>{item.phone}</h5></td>
                                            <td>
                                                <div className="d-flex justify-content-start align-items-center mt-2">
                                                    <img src={item.status == 0 ? bdot : gdot} alt="image..." />
                                                    <h5 className='mb-0 ms-1'>
                                                        {item.status == 0 ? "On Trip" : "Available"}
                                                    </h5>
                                                </div>
                                            </td>
                                            <td className='text-center'><h5 className='mt-2'>{item.truck_no ? item.truck_no : "-"}</h5></td>
                                            <td className='text-center'><h5 className='mt-2 '>{item.start_loc} - {item.destination}</h5></td>
                                            {/* <td><Button className='mt-2 bg-blue text-white btn-sm rounded-4' onClick={() => { handleuser(item.id) }}>View Details</Button></td> */}
                                        </tr>
                                    )) : <tr className='pt-4 text-center' >

                                        <td colSpan={6}><h5 className='mt-2'>No Driver Found</h5></td>

                                    </tr>}

                                </tbody>
                            </table>
                        </div>

                    </Card>
                </div>
            </Container>
            <Modal show={show} onHide={handleClose} centered >
                <Modal.Header className='bg-blue rounded-top-4'>
                    <Modal.Title className='fs-4 text-white'>Choose Driver</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>

                        <Row className='px-3 g-2'>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Driver Name</h6>
                                <select className='form-select rounded-4' name="driverid" onChange={handlechange}>
                                    <option value="">Select Driver</option>
                                    {driver.map((item, index) => (
                                        <option value={item.id} key={index}>{item.name}</option>
                                    ))}
                                </select>
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6 mt-3'>Recent Drivers</h6>
                                <div className="d-flex ">
                                    <Button className='bg-blue rounded-4 btn-sm me-2'>Raja</Button>
                                    <Button className='bg-blue rounded-4 btn-sm me-2'>Radhe</Button>
                                    <Button className='bg-blue rounded-4 btn-sm me-2'>Ajay</Button>
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' onClick={modal ? handleShow2 : handleShow3} >Save</Button>
                                </div>
                            </Col>
                        </Row>


                    </form>
                </Modal.Body>
            </Modal>
            <Modal show={show2} onHide={handleClose2} centered >
                <Modal.Header className='bg-blue rounded-top-4'>
                    <Modal.Title className='fs-4 text-white'>Driver Gave (-)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handlegave}>

                        <Row className='px-3 g-2'>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Total Amount</h6>
                                <input type="Number" className='form-control mb-3  rounded-4' placeholder='Total Amount' name="amount" onChange={handlechange} />
                            </Col>
                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Reason</h6>
                                <select className='form-select rounded-4' name="reason" onChange={handlechange}>
                                    <option value="">Select reason</option>
                                    <option value="Fuel Expense">Fuel Expense</option>
                                    <option value="Loading Charges">Loading Charges</option>
                                    <option value="Uploading Charges">Uploading Charges</option>
                                    <option value="Detention Charges">Detention Charges</option>
                                    <option value="Union Charges">Union Charges</option>
                                    <option value="Toll Charges">Toll Charges</option>
                                    <option value="Police Expense">Police Expense</option>
                                    <option value="RTO Expense">RTO Expense</option>
                                </select>
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6 mt-3'>Date</h6>
                                <input type="date" className='form-control  mb-3 rounded-4' placeholder='Date' name="date" onChange={handlechange} />
                            </Col>
                            <Col md={12}>
                                <h6 className='text-blue fw-6 mt-3'>Type Note</h6>
                                <input type="text" className='form-control  mb-3 rounded-4' placeholder='Type Note' name="type" onChange={handlechange} />
                            </Col>

                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose2}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' onClick={handleShow2} type="submit">Save</Button>
                                </div>
                            </Col>
                        </Row>


                    </form>
                </Modal.Body>
            </Modal>
            <Modal show={show3} onHide={handleClose3} centered >
                <Modal.Header className='bg-blue rounded-top-4'>
                    <Modal.Title className='fs-4 text-white'>Driver Got (+)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handlegave}>

                        <Row className='px-3 g-2'>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Total Amount</h6>
                                <input type="Number" className='form-control mb-3  rounded-4' placeholder='Total Amount' name="amount" onChange={handlechange} />
                            </Col>
                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Reason</h6>
                                <select className='form-select rounded-4' name="reason" onChange={handlechange}>
                                    <option value="">Select Reason</option>
                                    <option value="Trip Advance">Trip Advance</option>
                                    <option value="Trip Payment">Trip Payment</option>
                                    <option value="Party Advance">Party Advance</option>
                                    <option value="Party Payment">Party Payment</option>
                                    <option value="Driver Payment">Driver Payment</option>
                                </select>
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6 mt-3'>Date</h6>
                                <input type="date" className='form-control  mb-3 rounded-4' placeholder='Date' name="date" onChange={handlechange} />
                            </Col>
                            <Col md={12}>
                                <h6 className='text-blue fw-6 mt-3'>Type Note</h6>
                                <input type="text" className='form-control  mb-3 rounded-4' placeholder='Type Note' name="type" onChange={handlechange} />
                            </Col>

                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose3}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' onClick={handleShow3} type='submit'>Save</Button>
                                </div>
                            </Col>
                        </Row>


                    </form>
                </Modal.Body>
            </Modal>


            <Modal show={show4} onHide={handleClose4} centered >
                <Modal.Header className='bg-blue rounded-top-4'>
                    <Modal.Title className='fs-4 text-white'>Add Driver Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>

                        <Row className='px-3 g-2'>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Driver Name</h6>
                                <input type="text" className='form-control mb-3  rounded-4' name='name' placeholder='Enter Name' onChange={handleinput} />
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Mobile Number</h6>
                                <input type="Number" className='form-control  mb-3   rounded-4' name='phone' placeholder='+91 Enter Mobile Number' onChange={handleinput} />
                            </Col>

                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose4}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' type="submit" >Save</Button>
                                </div>
                            </Col>
                        </Row>


                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Driver;