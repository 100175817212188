import React, { useState } from 'react';
import logo from '../assests/new3/logo_blue.svg';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function ForgetPass() {

    const [sendOTP, setSendOTP] = useState(false);
    const [verifyOTP, setVerifyOTP] = useState(false);

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const navigate = useNavigate();

    const handleContinue = () => {
        const newid = toast.loading("Please wait...");
        
        toast.update(newid, {
            render: "Redirecting to LOGIN PAGE !",
            type: "success",
            isLoading: false,
            icon: true,
            autoClose: 3000,
            closeButton: true,
            closeOnClick: true,
            onClose: () => {
                navigate('/login');
            }
        });
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    const handleSendOTP = () => {
        const newid = toast.loading("Please wait...");
        
        toast.update(newid, {
            render: "Sending OTP!",
            type: "success",
            isLoading: false,
            icon: true,
            autoClose: 3000,
            closeButton: true,
            closeOnClick: true,
            onClose: () => {
                setSendOTP(true);
               //console.log('sendOTP');
            }
        });
    }

    const handleVerifyOTP = () => {
        const newid = toast.loading("Please wait...");
        
        toast.update(newid, {
            render: "Verifying OTP!",
            type: "success",
            isLoading: false,
            icon: true,
            autoClose: 3000,
            closeButton: true,
            closeOnClick: true,
            onClose: () => {
                setVerifyOTP(true);
               //console.log('verifyOTP');
            }
        });
    }

    return (
        <div className="login_bg_image">
            <ToastContainer />
            <Container fluid>
                <Row className="py-0">
                    <Col md={7}></Col>
                    <Col md={5} className="align-middle">
                        <div className={`p-3 full_height card-container`}>
                            <div className={`h-100 bg-white d-flex row justify-content-center align-items-center p-5 login_form rounded-4 card-content`}>
                                <div className="text-center">
                                    <img src={logo} width={200} alt="Freight" />
                                    <h2 className="text-dark fw-5 mt-4">
                                        Forget Password
                                    </h2>
                                    <div className="mt-5">
                                        <input
                                            type="number"
                                            className={`form-control mb-3 rounded-4 `}
                                            readOnly={sendOTP}
                                            name="name"
                                            placeholder="mobile number"
                                        />
                                        <Button className={`btn bg-blue text-white w-100 rounded-4 border-0 ${sendOTP ? 'd-none' : ''}`} onClick={handleSendOTP}>
                                            Send OTP
                                        </Button>
                                        
                                        <input
                                            type="number"
                                            className={`form-control mb-3 rounded-4 ${sendOTP ? '' : 'd-none'} ${verifyOTP ? 'd-none' : ''}`}
                                            name="otp"
                                            placeholder="enter otp"
                                        />
                                        <Button className={`btn bg-blue text-white w-100 rounded-4 border-0 ${sendOTP ? '' : 'd-none'} ${verifyOTP ? 'd-none' : ''}`} onClick={handleVerifyOTP}>
                                            Verify OTP
                                        </Button>

                                        {/* <input
                                            type="password"
                                            className={`form-control mb-3 rounded-4 ${verifyOTP ? '' : 'd-none'}`}
                                            name="password"
                                            placeholder="enter password"
                                        /> */}

                                        {/* Password Field */}
                                        <div className={`input-group mb-3 ${verifyOTP ? '' : 'd-none'}`}>
                                            <input
                                                type={passwordVisible ? "text" : "password"}
                                                className={`form-control rounded-4`}
                                                name="password"
                                                placeholder="Enter password"
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text" onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                                                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                                </span>
                                            </div>
                                        </div>

                                        {/* Confirm Password Field */}
                                        <div className={`input-group mb-3 ${verifyOTP ? '' : 'd-none'}`}>
                                            <input
                                                type={confirmPasswordVisible ? "text" : "password"}
                                                className={`form-control rounded-4`}
                                                name="confirmPassword"
                                                placeholder="Confirm password"
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text" onClick={toggleConfirmPasswordVisibility} style={{ cursor: 'pointer' }}>
                                                    {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                                                </span>
                                            </div>
                                        </div>
                                        <Button className={`btn bg-blue text-white w-100 rounded-4 border-0 ${verifyOTP ? '' : 'd-none'}`} onClick={handleContinue}>
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ForgetPass;