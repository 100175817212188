

import React, { useState, useEffect } from 'react';
import logo from '../assests/logo_blue.svg';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from '../App'
import axios from 'axios';
import { useAuth } from './AuthContext';
import { useNavigate, Navigate } from 'react-router-dom';
export default function Login() {

    const nevigate = useNavigate();
    const [currPage, setCurrPage] = useState('login');
    const [form, setForm] = useState({});
    const { setToken, isLoading } = useAuth();
    const isAuth = sessionStorage.getItem('isAuth')
    const handleinput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        })
        setErrors({
            ...errors,
            [e.target.name]: '',
        })

    }
    const [errors, setErrors] = useState()
    function validateLoginForm(form) {
        const newErrors = {};
        let checkIncomplete = false;

        if (!form.login_type || form.login_type.trim() === '') {
            newErrors.login_type = 'Name is required';
            checkIncomplete = true;
        } else {
            newErrors.login_type = '';
        }

        // Validate mobile number
        if (!form.login_number || form.login_number.trim() === '') {
            newErrors.login_number = 'Mobile number is required';
            checkIncomplete = true;
        } else {
            newErrors.login_number = '';
        }

        // Validate password
        if (!form.login_password || form.login_password.trim() === '') {
            newErrors.login_password = 'Password is required';
            checkIncomplete = true;
        } else {
            newErrors.login_password = '';
        }
        //console.log('Validation errors:', newErrors);
        setErrors(prevErrors => ({
            ...prevErrors,
            ...newErrors
        }));

        return checkIncomplete;
    }
    function validateSignUpForm(form) {
        const newErrors = {};
        let checkIncomplete = false;

        // Basic regex for mobile number validation (adjust as needed for your requirements)
        const mobileNumberRegex = /^\d{10}$/; // Example for a 10-digit mobile number

        // Basic regex for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email format validation

        // Validate name
        if (!form.sign_type || form.sign_type.trim() === '') {
            newErrors.sign_type = 'Name is required';
            checkIncomplete = true;
        } else {
            newErrors.sign_type = '';
        }

        if (!form.sign_name || form.sign_name.trim() === '') {
            newErrors.sign_name = 'Name is required';
            checkIncomplete = true;
        } else {
            newErrors.sign_name = '';
        }

        // Validate email
        if (!form.sign_email || form.sign_email.trim() === '') {
            newErrors.sign_email = 'Email is required';
            checkIncomplete = true;
        } else if (!emailRegex.test(form.sign_email.trim())) {
            newErrors.sign_email = 'Invalid email format';
            checkIncomplete = true;
        } else {
            newErrors.sign_email = '';
        }

        // Validate mobile number
        if (!form.sign_number || form.sign_number.trim() === '') {
            newErrors.sign_number = 'Mobile number is required';
            checkIncomplete = true;
        } else if (!mobileNumberRegex.test(form.sign_number.trim())) {
            newErrors.sign_number = 'Invalid mobile number format';
            checkIncomplete = true;
        } else {
            newErrors.sign_number = '';
        }

        // Validate password
        if (!form.sign_password || form.sign_password.trim() === '') {
            newErrors.sign_password = 'Password is required';
            checkIncomplete = true;
        }   
        else if (form.sign_password.trim().length < 6) { // Example minimum password length
            newErrors.sign_password = 'Password must be at least 6 characters long';
            checkIncomplete = true;
        }
        else {
            newErrors.sign_password = '';
        }

        // Validate confirm password
        if (!form.confirmPassword || form.confirmPassword.trim() === '') {
            newErrors.confirmPassword = 'Confirm password is required';
            checkIncomplete = true;
        } else if (form.confirmPassword.trim() !== form.sign_password.trim()) {
            newErrors.confirmPassword = 'Passwords do not match';
            checkIncomplete = true;
        } else {
            newErrors.confirmPassword = '';
        }

        // Update the errors state
        setErrors(prevErrors => ({
            ...prevErrors,
            ...newErrors
        }));

        //console.log('Validation errors:', newErrors);
        return checkIncomplete;
    }

    const handlesignup = (e) => {
        e.preventDefault()
        const isValid = validateSignUpForm(form)
        if (!isValid) {
            const newid = toast.loading("Please wait...", {
                render: "Signup Successfully !",
                type: "loading",
                icon: true,
                autoClose: 3000,
                closeButton: true,
                closeOnClick: true,
            })

            //console.log(isValid, 'signupvalid')



            axios.post('https://v4masters.com/backend/public/signup', form)
                .then((response) => {
                    //console.log(response.data, "signupdata")
                    if (response.data === "success") {
                        toast.update(newid, {
                            render: "Signup Successfully !",
                            type: "success",
                            isLoading: false,
                            icon: true,
                            autoClose: 3000,
                            closeButton: true,
                            closeOnClick: true,
                        })
                        setTimeout(() => {
                            setCurrPage('login')
                            setForm(null)
                            // setAuth(true);
                        }, 3000)
                    } else if (response.data === "Already register") {
                        toast.update(newid, {
                            render: "Already register with this number",
                            type: "info",
                            isLoading: false,
                            icon: true,
                            autoClose: 3000,
                            closeButton: true,
                            closeOnClick: true,
                        })
                        setTimeout(() => {
                            setCurrPage('login')
                            setForm(null)
                            // setAuth(true);
                        }, 3000)
                    } else {
                        setTimeout(() => {
                            toast.update(newid, {
                                render: "Something went wrong !!",
                                type: "error",
                                isLoading: false,
                                icon: true,
                                autoClose: 3000,
                                closeButton: true,
                                closeOnClick: true,
                            })
                        }, 1000);
                    }

                })
                .catch((error) => {

                    //console.log(error)
                })

        }

    }
    // const [token, setToken] = useState()
    const handlelogin = (e) => {
        e.preventDefault()
        const isValid = validateLoginForm(form)
        ////console.log(isValid, 'valid')
        if (!isValid) {
            const newid = toast.loading("Please wait...")
            axios.post('https://v4masters.com/backend/public/login', form)
                .then((response) => {
                    //console.log(response.data, "login")
                    if (response.data === "error") {
                        setTimeout(() => {
                            toast.update(newid, {
                                render: "Invalid Credentials",
                                type: "error",
                                isLoading: false,
                                icon: true,
                                autoClose: 3000,
                                closeButton: true,
                                closeOnClick: true,
                            })
                        }, 1000);

                    } else {
                        toast.update(newid, {
                            render: "Login Successfully !",
                            type: "success",
                            isLoading: false,
                            icon: true,
                            autoClose: 3000,
                            closeButton: true,
                            closeOnClick: true,
                        })
                        setTimeout(() => {
                            sessionStorage.setItem('token', response.data);

                            // setUserId(response.data.id);
                            // sessionStorage.setItem('user_type', response.data.user_type);
                            // sessionStorage.setItem("isAuthenticate", true);
                            setToken(response.data)

                            // nevigate('/')
                            window.location.href = '/'

                            // <Navigate to="/" replace={true} />
                            // sessionStorage.setItem();

                        }, 3000)
                    }

                })
                .catch((error) => {

                    //console.log(error)
                })

        }

    }


    const handleNavigateToForgetPass = () => {
        //console.log("msbcjhZFcgy")
        nevigate('/forgetpass');
    };
    useEffect(() => {
        setForm({
            ...form,
            'currstatus': currPage
        })
    }, [currPage])
    useEffect(() => {
        if (isAuth)
            nevigate('/')
    }, [isAuth])


    return (
        <div className="login_bg_image">

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable

                theme="dark"

            />
            {/* {JSON.stringify(errors)} */}
            <Container fluid>
                <Row className="py-0">
                    <Col md={7}></Col>
                    <Col md={5} className="align-middle">
                        <div className={`p-3 full_height card-container ${currPage === 'signup' ? 'rotate' : 'rotate_rev'}`}>
                            <div className={`h-100 bg-white d-flex row justify-content-center align-items-center p-5 login_form rounded-4 card-content ${currPage === 'signup' ? 'rotate' : 'rotate_rev'}`}>
                                <div className="text-center">
                                    <img src={logo} width={200} alt="Freight" />
                                    <h2 className="text-dark fw-5 mt-4">
                                        Let’s <span className="text-blue">{currPage === 'login' ? 'Login' : 'Signup'}</span> to the Portal
                                    </h2>
                                    <div className="mt-4">
                                        {currPage === 'signup' ? <>

                                            <select className={`form-control mb-3 border  rounded-4 ${errors?.sign_type ? 'border-danger' : 'border-transparent'}`} name="sign_type" onChange={handleinput}>
                                                <option value="">Signup Type</option>
                                                <option value="0">Admin</option>
                                                <option value="1">User</option>
                                                {/* <option value="2">Delivery</option> */}
                                            </select>
                                            <input
                                                type="text"
                                                className={`form-control mt-1 border  rounded-4 ${errors?.sign_name ? 'border-danger' : 'border-transparent'}`}
                                                name="sign_name"
                                                placeholder="Enter Name"
                                                value={form?.sign_name || ''}
                                                onChange={handleinput}
                                            />
                                            <div className='text-danger text-start fs-3'>{errors?.sign_name ? errors.sign_name : <>&nbsp;</>}</div>
                                            <input
                                                type="email"
                                                className={`form-control mt-1 border rounded-4 ${errors?.sign_email ? 'border-danger' : 'border-transparent'}`}
                                                name="sign_email"
                                                placeholder="Enter Email"
                                                value={form?.sign_email || ''}
                                                onChange={handleinput}
                                            />
                                            <div className='text-danger text-start fs-3'>{errors?.sign_email ? errors.sign_email : <>&nbsp;</>}</div>
                                            <input
                                                type="text"
                                                className={`form-control border mt-1 rounded-4 ${errors?.sign_number ? 'border-danger' : 'border-transparent'}`}
                                                name="sign_number"
                                                placeholder="Enter mobile number"
                                                value={form?.sign_number || ''}
                                                onChange={handleinput}
                                            />
                                            <div className='text-danger text-start fs-3'>{errors?.sign_number ? errors.sign_number : <>&nbsp;</>}</div>
                                            <input
                                                type="password"
                                                className={`form-control border mt-1 rounded-4 ${errors?.sign_password ? 'border-danger' : 'border-transparent'}`}
                                                name="sign_password"
                                                placeholder="Enter password"
                                                value={form?.sign_password || ''}
                                                onChange={handleinput}
                                            />
                                            <div className='text-danger text-start fs-3'>{errors?.sign_password ? errors.sign_password : <>&nbsp;</>}</div>

                                        </> : <>
                                            <select className={`form-control border mb-3 rounded-4 ${errors?.login_type ? 'border-danger' : 'border-transparent'}`} name="login_type" value={form?.login_type || ''} onChange={handleinput}>
                                                <option value="">Login Type</option>
                                                <option value="0">Admin</option>
                                                <option value="1">User</option>
                                                {/* <option value="2">Delivery</option> */}
                                            </select>
                                            <input
                                                type="text"
                                                className={`form-control border mb-3 rounded-4 ${errors?.login_number ? 'border-danger' : 'border-transparent'}`}
                                                name="login_number"
                                                placeholder="mobile number"
                                                value={form?.login_number || ''}
                                                onChange={handleinput}
                                            />
                                            <input
                                                type="password"
                                                className={`form-control border mb-3 rounded-4 ${errors?.login_password ? 'border-danger' : 'border-transparent'}`}
                                                name="login_password"
                                                placeholder="enter password"
                                                value={form?.login_password || ''}
                                                onChange={handleinput}
                                            />
                                        </>}

                                        {currPage === 'signup' && (
                                            <><input
                                                type="password"
                                                className={`form-control border mt-1  rounded-4 ${errors?.confirmPassword ? 'border-danger' : 'border-transparent'}`}
                                                name="confirmPassword"
                                                placeholder="confirm password"
                                                onChange={handleinput} />
                                                <div className='text-danger text-start mb-3 fs-3'>{errors?.confirmPassword ? errors.confirmPassword : <>&nbsp;</>}</div>
                                            </>
                                        )}
                                    </div>
                                    {/* <div className={`text-blue text-start fw-normal pointer mt-4 ${currPage === 'login' ? '' : 'd-none'}`} onClick={handleforgot}>Forget Password?</div> */}
                                    <div
                                        className={`text-blue text-start fw-normal pointer mt-4 ${currPage === 'login' ? '' : 'd-none'}`}
                                        onClick={handleNavigateToForgetPass}
                                    >
                                        Forget Password?
                                    </div>
                                    <button
                                        className={`btn bg-blue text-white w-100 rounded-4 border-0 ${currPage === 'login' ? '' : 'mt-4'}`} onClick={currPage === 'login' ? handlelogin : handlesignup}>
                                        {currPage === 'login' ? 'Login' : 'Signup'}
                                    </button>
                                    <button
                                        className="btn mt-4 bg-white text-blue w-100 rounded-4 border border-2 fw-6"
                                        onClick={() => { setCurrPage(currPage === 'login' ? 'signup' : 'login'); setErrors(null); }}
                                    >
                                        {currPage === 'login' ? 'Signup' : 'Login'}
                                    </button>
                                </div>
                                <p className="text-dark text-center fw-normal fw-5">
                                    By {currPage === 'login' ? 'logging in' : 'signing up'}, you agree to all the{' '}
                                    <span className="text-blue pointer">terms and conditions.</span>
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

        </div>
    );
}
