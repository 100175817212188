import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const Notverify = () => {
    // const isAuth = sessionStorage.getItem("isAuthenticate");
    const usertype = sessionStorage.getItem("user_type");
    const userid = sessionStorage.getItem("id");
    const user_verify = sessionStorage.getItem("user_verify");
    const { token, setToken, userData, setUserData, validateToken, isAuth, setIsAuth } = useAuth();

    const [user, setUser] = useState([]);

    const navigate = useNavigate()
    const handleLogout = () => {
        sessionStorage.clear();
        setToken(null)
        setUserData(null)
        setIsAuth(false)
        navigate('/login');

    }
    return (
        <div>
            {isAuth ? (
                <>

                    {user_verify === "0" ? (
                        <>
                            <div className='d-flex align-items-center justify-content-center w-100 flex-column  usernotverify'>
                                User Not Verified Please Contact to Administrator
                                <button className='btn fs-5 ps-4 fw-6 border border-2 pe-4' onClick={handleLogout}>Log Out</button>
                            </div>
                        </>
                    ) : (

                        navigate('/')

                    )}

                </>
            ) : (
                <>

                </>
            )}

        </div>
    )
}

export default Notverify